import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  forgotPassword,
  getUserDetails,
  Login,
  ResendOtp,
  resetPassword,
  VerifyOtp,
} from "../api/login";
import { getTeacherDetails } from "../api/teacher";

const initialState = {
  isLoading: false,
};

const slice = createSlice({
  name: "slice",
  initialState,
  reducers: {
    isLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    teacherDetail: (state, action) => {
      state.teacherDetail = action.payload;
    },
  },
});

export const getTeacherInfo = (state) => state?.slice?.teacherDetail;

export const FetchTeacherDetails = createAsyncThunk(
  "fetchTeacherDetails",
  async (obj, { dispatch }) => {
    try {
      //   dispatch(isLoading(true));
      const response = await getUserDetails(obj);
      if (response?.code === 1) {
        dispatch(teacherDetail(response?.data?.user));
      }
      dispatch(isLoading(false));

      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const loginCall = createAsyncThunk(
  "loginCall",
  async (obj, { dispatch }) => {
    try {
      //   dispatch(isLoading(true));
      const response = await Login(obj);
      dispatch(isLoading(false));

      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const verifyOtpCall = createAsyncThunk(
  "VerifyOtpCall",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));
      const response = await VerifyOtp(obj);
      dispatch(isLoading(false));

      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const ResendOtpCall = createAsyncThunk(
  "ResendOtpCall",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));
      const response = await ResendOtp(obj);
      dispatch(isLoading(false));
      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const resetPasswordCall = createAsyncThunk(
  "resetPasswordCall",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));
      const response = await resetPassword(obj);
      dispatch(isLoading(false));

      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const forgotPasswordCall = createAsyncThunk(
  "forgotPassword",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));
      const response = await forgotPassword(obj);
      dispatch(isLoading(false));

      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const { isLoading, teacherDetail } = slice.actions;

export default slice.reducer;
