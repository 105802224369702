import { motion } from "framer-motion";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import {
  pageTransitionVaraint,
  staggerVariant,
} from "../../constant/Variants.js";
import { forgetPasswordSchema } from "../../constant/schema.js";
import { yupResolver } from "@hookform/resolvers/yup";
import { forgotPasswordCall } from "../slice.js";
import Loader from "../../component/Loader.jsx";

function ForgetPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onSubmit",
    resolver: yupResolver(forgetPasswordSchema),
    shouldFocusError: true,
  });

  const onSubmit = (data) => {
    setLoading(true);
    dispatch(
      forgotPasswordCall({
        email: data?.email?.trim()?.toLowerCase(),
        user_type: "teacher",
      })
    ).then(async (res) => {
      setLoading(false);
      if (res?.payload?.code === 1) {
        await localStorage.setItem(
          "forgetdata",
          JSON.stringify(res?.payload?.data)
        );
        await localStorage.setItem("email", data?.email?.trim()?.toLowerCase());
        navigate("/otp", { replace: true });

        toast.success(res?.payload?.message);
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };
  return (
    <section className="bg-image">
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6">
            <div className="logo-bg position-relative">
              <p>
                <img
                  src="assets/img/headerlogo.png"
                  className="logo-icon"
                  alt="logo"
                />
              </p>
              <p>
                <img
                  src="assets/img/banner_logo.png"
                  className="logo-img"
                  alt="logo"
                />
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 mt-5">
            <div className="form-style mt-5">
              <form className="row" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group col-sm-12 col-md-12 text-center mb-5">
                  <div className="">
                    <img
                      src="assets/img/forgot-password.png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
                <div className="form-group col-sm-12 col-md-12 text-center mb-4">
                  <h2 className="mdl-ttl">forgot password</h2>
                  <p>
                    In order to retrieve your password, please enter registered
                    email address
                  </p>
                </div>

                <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                  <label className="fw-bold">Email</label>
                  <div className="input-container">
                    <input
                      type="text"
                      className="form-control ps-4"
                      placeholder="Email"
                      name="email"
                      {...register("email")}
                    />
                    <img
                      src="assets/img/email.png"
                      className="input-img"
                      alt=""
                    />
                  </div>
                  {errors?.email && (
                    <div className="text-red">{errors?.email?.message}</div>
                  )}
                </div>
                <div className="form-group col-sm-12 col-md-12 text-center">
                  <button type="submit" className="btn btn-submit" disabled={loading}>
                    {loading ? <Loader /> : "Submit"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ForgetPassword;
