import {
  getData,
  getDataforUrl,
  patchFormData,
  patchURLEncodedData,
  putFormData,
} from ".";

export const getStudentList = (payload) => {
  return getData("admin/studentList", payload).then((data) => {
    return data;
  });
};

export const getAreacodeList = (payload) => {
  return getData("admin/areaCodeList", payload).then((data) => {
    return data;
  });
};
export const getDisciplineList = (payload) => {
  return getData("admin/disciplineList", payload).then((data) => {
    return data;
  });
};

export const allStudentListForAClass = async (payload) => {
  return getDataforUrl("class/getAllClassStudents", payload).then((data) => {
    return data;
  });
};

export const allStudentListForAnEvent = async (payload) => {
  return getDataforUrl("class/getAllEventStudents", payload).then((data) => {
    return data;
  });
};
