import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import Loader from "./../../component/Loader";
import BackButton from "./../../component/Modal/BackButton";
import {
  clearAllStudentList,
  FetchAllStudentsForClass,
  FetchAllStudentsForEvent,
} from "./ClassSlice";

function AllStudents() {
  const { id, name } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const register_for = useSelector((state) => state.class.register_for);
  console.log(register_for);
  const data = useSelector((state) => state.class.details);
  const allStudentForClass = useSelector(
    (state) => state.class.allStudentForClasses
  );
  const allStudentForEvent = useSelector(
    (state) => state.class.allStudentForEvents
  );
  useEffect(() => {
    if (register_for === "class") {
      setLoading(true);
      dispatch(FetchAllStudentsForClass(id)).then(() => {
        setLoading(false);
      });
    } else {
      setLoading(true);
      dispatch(FetchAllStudentsForEvent(id)).then(() => {
        setLoading(false);
      });
    }
  }, [dispatch, id, register_for]);

  useEffect(() => {
    return () => {
      dispatch(clearAllStudentList());
    };
  }, [dispatch]);
  return (
    <article class="col-lg-12">
      <div class="article-body store-bd-gy">
        <div class="row align-items-center col-rever">
          <BackButton />

          <div class="col-sm-12 col-md-12 col-lg-12 mb-3 text-center">
            <h3>All Student For {name}</h3>
          </div>

          <div className="col-sm-12 ">
            <div class="table-responsive text-center">
              <table class="table storetable">
                <thead class="text-center">
                  <tr>
                    <th scope="col">full name</th>
                    {/* <th scope="col">full address</th> */}
                    <th scope="col">membership</th>
                    <th scope="col">membership level</th>
                    <th scope="col">Actions</th>
                  </tr>
                  <tr class="border-0 bg-none invisible">
                    <td class="border-0 bg-none"></td>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan={5}>
                        <Loader />
                      </td>
                    </tr>
                  ) : allStudentForClass?.length > 0 ? (
                    allStudentForClass?.map((student, i) => (
                      <tr key={i}>
                        <td
                          onClick={() => {
                            navigate(`/student/${student?.id}`);
                          }}
                          className="cursor-pointer"
                        >
                          {student?.first_name} {student?.last_name}
                        </td>

                        <td>
                          {student?.membership_details ? "Active" : "Trail"}
                        </td>
                        <td>{student?.student_type || "NA"}</td>
                        <td>
                          <button className="btn" title="View User">
                            <Link to={`/student/${student?.id}`}>
                              <img src="assets/images/view.png" alt="" />
                            </Link>
                          </button>
                          {/* <button
                            className="btn mx-1 attendanceButton"
                            type="button"
                            onClick={() => {
                              StudentAttendance({
                                dataa: student,
                                status: "Present",
                              });
                            }}
                          >
                            Mark Present
                          </button>
                          <button
                            className="btn mx-1 attendanceButton"
                            type="button"
                            onClick={() => {
                              StudentAttendance({
                                dataa: student,
                                status: "Absent",
                              });
                            }}
                          >
                            Mark Absent
                          </button> */}
                          {/* {register_for === "class" && (
                            <button
                              className="btn mx-1 attendanceButton"
                              type="button"
                              onClick={() => {
                                setChangeClassForStudentData({
                                  studentId: student?.id,
                                  student_type: student?.student_type,
                                  trialAccountStatus:
                                    student?.membership_details ? "3" : "1",
                                  time_slot_for: student?.student_type,
                                  adult_price: data?.adult_price,
                                  child_price: data?.child_price,
                                  teacherId:
                                    data?.assigned_teacher?.teacher_details?.id,
                                  schoolId: data?.school_details?.id,
                                  old_booking_student_id:
                                    student?.old_booking_student_id,
                                  userId: student?.parent_address?.id,
                                });
                                handleShow();
                              }}
                            >
                              Change Class
                            </button>
                          )
                          } */}
                        </td>
                      </tr>
                    ))
                  ) : allStudentForEvent?.length > 0 ? (
                    allStudentForEvent?.map((student, i) => (
                      <tr key={i}>
                        <td
                          onClick={() => {
                            navigate(`/student/${student?.id}`);
                          }}
                          className="cursor-pointer"
                        >
                          {student?.first_name} {student?.last_name}
                        </td>

                        <td>
                          {student?.membership_details ? "Active" : "Trail"}
                        </td>
                        <td>{student?.student_type || "NA"}</td>
                        <td>
                          <button className="btn" title="View User">
                            <Link to={`/student/${student?.id}`}>
                              <img src="assets/img/view.png" alt="" />
                            </Link>
                          </button>
                          {/* <button
                            className="btn mx-1 attendanceButton"
                            type="button"
                            onClick={() => {
                              StudentAttendance({
                                dataa: student,
                                status: "Present",
                              });
                            }}
                          >
                            Mark Present
                          </button>
                          <button
                            className="btn mx-1 attendanceButton"
                            type="button"
                            onClick={() => {
                              StudentAttendance({
                                dataa: student,
                                status: "Absent",
                              });
                            }}
                          >
                            Mark Absent
                          </button> */}
                          {/* {register_for === "class" && (
                            <button
                              className="btn mx-1 attendanceButton"
                              type="button"
                              onClick={() => {
                                setChangeClassForStudentData({
                                  studentId: student?.id,
                                  student_type: student?.student_type,
                                  trialAccountStatus:
                                    student?.membership_details ? "3" : "1",
                                  time_slot_for: student?.student_type,
                                  adult_price: data?.adult_price,
                                  child_price: data?.child_price,
                                  teacherId:
                                    data?.assigned_teacher?.teacher_details?.id,
                                  schoolId: data?.school_details?.id,
                                  old_booking_student_id:
                                    student?.old_booking_student_id,
                                  userId: student?.parent_address?.id,
                                });
                                handleShow();
                              }}
                            >
                              Change Class
                            </button>
                          )
                          } */}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <td colSpan={6}>No student found.</td>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
}

export default AllStudents;
