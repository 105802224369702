import {
  getData,
  getDataAdmin,
  getDataforUrl,
  getDataforUrlWithParams,
  patchFormData,
  patchURLEncodedData,
  postFormData,
} from ".";
// page, limit, status (0=pending, 1=aceepted, 2 rejected), date(2022-06-16)
export const assignClassList = (payload) => {
  return getData("teacher/assignClassList", payload).then((data) => {
    return data;
  });
};

export const assignClassDetails = (payload) => {
  return getDataforUrl("teacher/assignClassDetails", payload).then((data) => {
    return data;
  });
};
// assign_class_id, status (1 = accept 2 = reject)
export const acceptRejectClass = async (payload) => {
  return patchURLEncodedData("teacher/acceptRejectClass", payload).then(
    (data) => {
      return data;
    }
  );
};

export const assignClassCalenderView = (payload) => {
  return getData("teacher/assignClassCalenderView", payload).then((data) => {
    return data;
  });
};

export const getAllClassStudents = (payload) => {
  return getDataforUrlWithParams("teacher/getAllClassStudents", payload).then(
    (data) => {
      return data;
    }
  );
};

export const getStudentDetails = (payload) => {
  return getDataforUrl("teacher/getStudentDetails", payload).then((data) => {
    return data;
  });
};
// studentId, classId, date, status (present/absent)
export const saveAttendance = (payload) => {
  return postFormData("teacher/saveAttendance", payload).then((data) => {
    return data;
  });
};

export const cancelClass = (payload) => {
  return patchFormData("class/cancelClass", payload).then((data) => {
    return data;
  });
};

export const registerDetails = async (payload) => {
  return getData("class/registerDetails", payload).then((data) => {
    return data;
  });
};

export const saveClassAttendance = async (payload) => {
  return postFormData("teacher/saveAttendance", payload).then((data) => {
    return data;
  });
};

export const saveEventAttendance = async (payload) => {
  return postFormData("teacher/saveEventAttendance", payload).then((data) => {
    return data;
  });
};

export const addStudentInClass = async (payload) => {
  return postFormData("class/addStudentInClass", payload).then((data) => {
    return data;
  });
};

export const addStudentInEvent = async (payload) => {
  return postFormData("class/addStudentInEvent", payload).then((data) => {
    return data;
  });
};
export const getAllOtherStudents = async (payload) => {
  return getDataforUrl("class/getAllOtherStudents", payload).then((data) => {
    return data;
  });
};

export const getAllOtherStudentsForEvent = async (payload) => {
  return getDataforUrl("class/getAllOtherStudentsForEvent", payload).then(
    (data) => {
      return data;
    }
  );
};

export const studentList = async (payload) => {
  return getData("admin/studentList", payload).then((data) => {
    return data;
  });
};

export const eventList = async (payload) => {
  return getData("user/eventList", payload).then((data) => {
    return data;
  });
};

export const registerList = async (payload) => {
  return getData("class/registerList", payload).then((data) => {
    return data;
  });
};
