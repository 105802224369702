import React, { Component } from "react";

import CreatableSelect from "react-select/creatable";

const components = {
  DropdownIndicator: null,
};

const createOption = (label) => ({
  label,
  value: label,
});

const customStyles = {
  control: (styles) => ({
    ...styles,
    // none of react-select's styles are passed to <Control />
    width: "95%",
    height: "40px",
    overflowY: "scroll",
    borderColor: "transparent",
    "&:focus": {
      borderColor: "transparent",
    },
    "&:hover": {
      borderColor: "transparent",
    },
    "&::-webkit-scrollbar": {
      width: "0px",
    },
  }),
};

export default class CreatableSelectInput extends Component {
  state = {
    inputValue: "",
    value: this.props.value.length > 0 ? this.props.value : [],
  };
  handleChange = (value, actionMeta) => {
    this.setState({ value });
  };
  handleInputChange = (inputValue) => {
    this.setState({ inputValue });
  };
  handleKeyDown = (event) => {
    const { inputValue, value } = this.state;
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        this.setState({
          inputValue: "",
          value: [...value, createOption(inputValue)],
        });
        this.props.handleChange([...value, createOption(inputValue)]);
        event.preventDefault();
        break;
      default:
        return false;
    }
  };
  render() {
    const { inputValue, value } = this.state;
    return (
      <CreatableSelect
        components={components}
        inputValue={inputValue}
        isClearable
        isMulti
        className={this.props.className}
        menuIsOpen={false}
        onChange={this.handleChange}
        onInputChange={this.handleInputChange}
        onKeyDown={this.handleKeyDown}
        placeholder={this.props.placeholder}
        value={value}
        styles={customStyles}
        onBlur={this.props.onBlur}
      />
    );
  }
}
