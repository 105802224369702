import { Formik } from "formik";
import { motion } from "framer-motion";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import * as Yup from "yup";
import {
  pageTransitionVaraint,
  staggerVariant,
} from "../../constant/Variants.js";
import { resetPasswordCall } from "../slice.js";
import { toast } from "react-toastify";
import Loader from "../../component/Loader.jsx";
function ResetPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const [passwordFor, setPasswordFor] = useState("");
  const [submitting, setSubmitting] = useState(false);
  return (
    <section className="bg-image">
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6">
            <div className="logo-bg position-relative">
              <p>
                <img
                  src="assets/img/headerlogo.png"
                  className="logo-icon"
                  alt="logo"
                />
              </p>
              <p>
                <img
                  src="assets/img/banner_logo.png"
                  className="logo-img"
                  alt="logo"
                />
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 mt-5">
            <div className="form-style mt-5">
              <Formik
                initialValues={{ password: "", confirmPassword: "" }}
                validationSchema={Yup.object({
                  password: Yup.string()
                    .matches(
                      // eslint-disable-next-line no-useless-escape
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#£€\$%\^&\*])(?=.{8,})/,
                      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
                    )
                    .required("Required"),
                  confirmPassword: Yup.string().oneOf(
                    [Yup.ref("password"), null],
                    "Password doesn't match."
                  ),
                })}
                onSubmit={(values) => {
                  setSubmitting(true);
                  dispatch(
                    resetPasswordCall({ password: values?.password })
                  ).then(async (res) => {
                    setSubmitting(false);

                    if (res?.payload?.code === 1) {
                      await localStorage.setItem(
                        "token",
                        res?.payload?.data?.token
                      );
                      navigate("/", { replace: true });

                      toast.success(res?.payload?.message);
                    } else {
                      toast.error(res?.payload?.message);
                    }
                  });
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                }) => (
                  <form className="row" onSubmit={handleSubmit}>
                    <div className="form-group col-sm-12 col-md-12 text-center mb-5">
                      <div className="">
                        <img
                          src="assets/img/change-password.png"
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="form-group col-sm-12 col-md-12 text-center mb-4">
                      <h2 className="mdl-ttl">reset password</h2>
                      <p>Please reset your password here</p>
                    </div>
                    <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                      <label className="fw-bold">New Password</label>

                      <div className="input-container">
                        <input
                          type={
                            toggle && passwordFor === "setPassword"
                              ? "text"
                              : "password"
                          }
                          className="form-control ps-4"
                          placeholder="Set New Password"
                          name="password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                        />
                        <img
                          src="assets/img/password.png"
                          className="input-img"
                          alt=""
                        />
                        <span
                          onClick={() => {
                            setPasswordFor("setPassword");
                            setToggle(!toggle);
                          }}
                          className="pass-view field-icon toggle-password cursor-pointer"
                        ></span>
                      </div>
                      {errors.password && touched.password && (
                        <div className="text-red">{errors.password}</div>
                      )}
                    </div>
                    <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                      <label className="fw-bold">Confirm Password</label>

                      <div className="input-container">
                        <input
                          type={
                            toggle && passwordFor === "confrimPassword"
                              ? "text"
                              : "password"
                          }
                          className="form-control ps-4"
                          placeholder="Confirm New Password"
                          name="confirmPassword"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.confirmPassword}
                        />
                        <img
                          src="assets/img/password.png"
                          className="input-img"
                          alt=""
                        />
                        <span
                          onClick={() => {
                            setPasswordFor("confrimPassword");
                            setToggle(!toggle);
                          }}
                          className="pass-view field-icon toggle-password cursor-pointer"
                        ></span>
                      </div>
                      {errors.confirmPassword && touched.confirmPassword && (
                        <div className="text-red">{errors.confirmPassword}</div>
                      )}
                    </div>
                    <div className="form-group col-sm-12 col-md-12 text-center">
                      <button
                        className="btn btn-submit"
                        type="submit"
                        disabled={submitting}
                      >
                        {submitting ? <Loader /> : "Done"}
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ResetPassword;
