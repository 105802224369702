import { useLayoutEffect, Suspense } from "react";
import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useRoutes,
} from "react-router-dom";
import Navbar from "../component/Navbar";
import CreateAccount from "../features/login/CreateAccount";
import QualificationInfo from "../features/login/QualificationInfo";
import Records from "../features/records/Records";
import PrivateRoute from "./PrivateRoute";
import Footer from "./../component/Footer";
import ClassDetail from "../features/class/ClassDetail";

import Notification from "../features/notification/Notification";
import ChangePassword from "../features/login/ChangePassword";
import EditProfile from "../features/profile/EditProfile";
import Login from "../features/login/Login";
import ForgetPassword from "../features/login/ForgetPassword";
import OtpVerification from "./../features/login/OtpVerification";
import ResetPassword from "./../features/login/ResetPassword";
import Myexpertise from "../features/profile/Myexpertise";
import { AnimatePresence, motion } from "framer-motion";
import {
  pageTransitionVaraint,
  privatePageTransition,
} from "../constant/Variants";
import Loader from "../component/Loader";
import RegisterEvents from "../features/events/RegisterEvents";
import Bookings from "../features/booking/Bookings";
import Appointments from "../features/appointments/Appointments";
import Register from "../features/class/Register";
import ClassRegister from "../features/class/ClassRegister";
import Student from "../features/profile/Student";
import RegisterWrapper from "../features/class/RegisterWrapper";
import EventsRegister from "../features/class/EventsRegister";
import RegisterList from "./../features/register/RegisterList";
import RegisterDetail from "../features/register/RegisterDetail";
import EventRegisterDetail from "../features/register/EventRegisterDetails";
import StudentList from "../features/student";
import AllStudents from "../features/class/AllStudents";

const publicRoutes = [
  { path: "/", element: <Login /> },
  { path: "/create-account", element: <CreateAccount /> },
  { path: "/qualification-info", element: <QualificationInfo /> },
  { path: "/change-password", element: <ChangePassword /> },
  { path: "/forget-password", element: <ForgetPassword /> },
  { path: "/otp", element: <OtpVerification /> },
  { path: "/reset-password", element: <ResetPassword /> },
];

const PublicLayout = () => {
  return <Outlet />;
};

const PrivateLayout = () => {
  return (
    <PrivateRoute>
      <Navbar />
      <Outlet />
      <Footer />
    </PrivateRoute>
  );
};

export function Router() {
  const location = useLocation();
  const { pathname } = location;

  const Routers = [
    {
      path: "/records",
      element: <Records />,
    },
    {
      path: "/class/:id",
      element: <ClassDetail />,
    },
    // {
    //   path: "/class-register/:id/:dateFor/:start_time/:end_time",
    //   element: <Register />,
    // },
    {
      path: "/class-register",
      element: <Register />,
    },
    {
      path: "/register",
      element: <RegisterList />,
      // children: [
      //   {
      //     path: "class",
      //     index: true,
      //     element: <Register />,
      //   },
      //   {
      //     path: "event",
      //     index: true,
      //     element: <EventsRegister />,
      //   },
      // ],
    },
    {
      path: "/register/class/:id/:dateFor/:time_slot_id",
      element: <RegisterDetail />,
    },
    {
      path: "/register/event/:id",
      element: <EventRegisterDetail />,
    },
    {
      path: "/register/class/:id/:dateFor/:start_time/:end_time/:time_slot_id",
      element: <RegisterDetail />,
    },
    {
      path: "/register/event/:id/:date/:time",
      element: <EventRegisterDetail />,
    },
    {
      path: "/student/:id",
      element: <Student />,
    },
    {
      path: "/students",
      element: <StudentList />,
    },
    {
      path: "/notification",
      element: <Notification />,
    },
    {
      path: "/profile",
      element: <EditProfile />,
    },
    {
      path: "/expertise",
      element: <Myexpertise />,
    },
    {
      path: "/register-events",
      element: <RegisterEvents />,
    },
    {
      path: "/my-bookings",
      element: <Bookings />,
    },
    {
      path: "/appointments",
      element: <Appointments />,
    },
    {
      path: "/registered-members/:id/:name",
      element: <AllStudents />,
    },
  ];

  useLayoutEffect(() => {
    window.scroll(0, 0);
  }, [location.pathname]);

  let element = useRoutes([
    {
      element: <PublicLayout />,
      children: publicRoutes,
    },
    {
      element: <PrivateLayout />,
      children: Routers,
    },
  ]);
  return element;
}
