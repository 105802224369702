import {
  getData,
  getDataforUrl,
  patchFormData,
  patchURLEncodedData,
  putFormData,
} from ".";

// export const assignClassList = (payload) => {
//   return getData("teacher/assignClassList", payload).then((data) => {
//     return data;
//   });
// };

export const getTeacherDetails = (payload) => {
  return getDataforUrl("admin/getTeacherDetails", payload).then((data) => {
    return data;
  });
};

export const editProfile = (payload) => {
  return putFormData("teacher/editProfile", payload).then((data) => {
    return data;
  });
};
