import React, { useState } from "react";
import { useNavigate } from "react-router";
// import OtpInput from "react-otp-input";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { ResendOtpCall, verifyOtpCall } from "../slice.js";
import { toast } from "react-toastify";
import { format } from "../../utils/index.js";
import OTPInput from "otp-input-react";
import Loader from "../../component/Loader.jsx";

function OtpVerification() {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("forgetdata"));
  const email = localStorage.getItem("email");
  const [loading, setLoading] = useState(false);
  const [counter, setCounter] = React.useState(60);

  React.useEffect(() => {
    let timer;
    if (counter > 0) {
      timer = setTimeout(() => setCounter((c) => c - 1), 1000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [counter]);

  const handleResend = () => {
    setLoading(true);
    dispatch(ResendOtpCall({ email: email, user_type: "teacher" })).then(
      async (res) => {
        setLoading(false);
        setCounter(10);
        if (res?.payload?.code === 1) {
          toast.success(res?.payload?.message);
        } else {
          toast.error(res?.payload?.message);
        }
      }
    );
  };
  return (
    <section className="bg-image">
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6">
            <div className="logo-bg position-relative">
              <p>
                <img
                  src="assets/img/headerlogo.png"
                  className="logo-icon"
                  alt="logo"
                />
              </p>
              <p>
                <img
                  src="assets/img/banner_logo.png"
                  className="logo-img"
                  alt="logo"
                />
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 mt-5">
            <div className="form-style mt-5">
              <Formik
                initialValues={{
                  otpValues: "",
                }}
                validationSchema={Yup.object({
                  otpValues: Yup.string()
                    .required("Required")
                    .matches(/^[0-9]+$/, "Must be only digits")
                    .min(6, "Kindly enter valid otp.")
                    .max(6, "Kindly enter valid otp."),
                })}
                onSubmit={(values, { isSubmitting, resetForm }) => {
                  dispatch(
                    verifyOtpCall({
                      userId: user?.userId,
                      code: values?.otpValues,
                      user_type: "teacher",
                    })
                  ).then(async (res) => {
                    if (res?.payload?.code === 1) {
                      await localStorage.setItem(
                        "token",
                        res?.payload?.data?.token
                      );
                      navigate("/reset-password", { replace: true });
                      toast.success(res?.payload?.message);
                    } else {
                      toast.error(res?.payload?.message);
                    }
                  });
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                }) => (
                  <form className="row" onSubmit={handleSubmit}>
                    <div className="form-group col-sm-12 col-md-12 text-center mb-5">
                      <div className="">
                        <img
                          src="assets/img/otp.png"
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="form-group col-sm-12 col-md-12 text-center mb-4">
                      <h2 className="mdl-ttl">OTP Verification</h2>
                      <p>
                        Please enter 6 digit code which has been send on your
                        <br /> registered email address.
                      </p>
                      {error && (
                        <div className="alert alert-danger">{error}</div>
                      )}
                    </div>
                    <div className="form-group col-sm-12 col-md-12 mb-4">
                      <div className="passcode-wrapper">
                        {/* <input
                        id="codeBox1"
                        type="number"
                        maxlength="1"
                        placeholder="0"
                      /> */}
                        <OTPInput
                          autofocus
                          value={values?.otpValues}
                          onChange={(val) => {
                            if (val) {
                              setFieldValue("otpValues", val);
                            }
                          }}
                          OTPLength={6}
                          otpType="number"
                          placeholder="000000"
                          disabled={false}
                          inputClassName="otpInputs"
                          onBlur={handleBlur}
                        />
                      </div>
                      {errors.otpValues && touched.otpValues && (
                        <div className="text-red">{errors.otpValues}</div>
                      )}
                    </div>
                    <div className="form-group col-sm-12 col-md-12 text-center">
                      <div>
                        {counter === 0 ? (
                          <span
                            className="forgot-pass cursor-pointer p-0"
                            onClick={() => {
                              handleResend();
                            }}
                          >
                            Resend Code
                          </span>
                        ) : (
                          <div>Resend code in {format(counter)}</div>
                        )}
                      </div>
                    </div>
                    <div className="form-group col-sm-12 col-md-12 text-center">
                      <button
                        className="btn btn-submit"
                        type="submit"
                        // onClick={() => {
                        // }}
                        disabled={loading}
                      >
                        {loading ? <Loader /> : "Verify"}
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default OtpVerification;
