import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  assignClassCalenderView,
  assignClassDetails,
  assignClassList,
  eventList,
  getAllClassStudents,
  getAllOtherStudents,
  getAllOtherStudentsForEvent,
  getStudentDetails,
  registerDetails,
  registerList,
  studentList,
} from "../../api/class";
import {
  allStudentListForAClass,
  allStudentListForAnEvent,
} from "../../api/student";

const initialState = {
  classList: [],
  studentList: [],
  scheduledClass: [],
  totalStudent: 0,
  register_for: localStorage.getItem("register_for")
    ? localStorage.getItem("register_for")
    : "class",
  eventList: [],
  registerList: [],
  totalRegister: 0,
  allStudentForClasses: [],
  allStudentForEvents: [],
};

export const ClassSlice = createSlice({
  name: "class",
  initialState,
  reducers: {
    scheduledClass: (state, action) => {
      state.scheduledClass = action.payload;
    },
    assignedClass: (state, action) => {
      state.classList = action.payload;
    },
    isLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    appointments: (state, action) => {
      state.appointments = action.payload;
    },
    allStudentOfAClass: (state, action) => {
      state.allStudentOfAClass = action.payload;
    },
    classDetail: (state, action) => {
      state.classDetail = action.payload;
    },
    totalStudentInClass: (state, action) => {
      state.totalStudent = action.payload;
    },
    studentDetail: (state, action) => {
      state.studentDetail = action.payload;
    },
    registerDetail: (state, action) => {
      state.classDetail = action.payload;
    },
    changeRegisterFor: (state, action) => {
      state.register_for = action.payload;
    },
    otherStudentListingForClass: (state, action) => {
      state.otherStudentsListForClass = action.payload;
    },
    otherStudentListingForEvent: (state, action) => {
      state.otherStudentsListForEvents = action.payload;
    },
    changeLevel: (state, action) => {
      state.level = action.payload;
    },
    studentlisting: (state, action) => {
      state.studentList = action.payload;
    },
    registerListing: (state, action) => {
      state.registerList = action.payload;
    },
    registerCount: (state, action) => {
      state.totalRegister = action.payload;
    },
    allStudentForClass: (state, action) => {
      state.allStudentForClasses = action.payload;
    },
    allStudentForEvent: (state, action) => {
      state.allStudentForEvents = action.payload;
    },
    clearAllStudentList: (state, action) => {
      state.allStudentForEvents = [];
      state.allStudentForClasses = [];
    },
  },
});

export const {
  assignedClass,
  scheduledClass,
  isLoading,
  appointments,
  allStudentOfAClass,
  classDetail,
  studentDetail,
  registerDetail,
  totalStudentInClass,
  otherStudentListingForClass,
  otherStudentListingForEvent,
  changeRegisterFor,
  studentlisting,
  changeLevel,
  eventListing,
  registerCount,
  registerListing,
  allStudentForClass,
  allStudentForEvent,
  clearAllStudentList,
} = ClassSlice.actions;

export const FetchAssignedCall = createAsyncThunk(
  "FetchAssignedCall",
  async (obj, { dispatch }) => {
    try {
      //   dispatch(isLoading(true));
      const response = await assignClassList(obj);
      dispatch(assignedClass(response?.data?.class_list));
      dispatch(isLoading(false));
      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const FetchScheduledCall = createAsyncThunk(
  "FetchScheduledCall",
  async (obj, { dispatch }) => {
    try {
      //   dispatch(isLoading(true));
      const response = await assignClassList(obj);
      dispatch(scheduledClass(response?.data?.class_list));

      dispatch(isLoading(false));
      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const getAppointments = (state) => state.class.appointments;
export const FetchAppointments = createAsyncThunk(
  "FetchAppointments",
  async (obj, { dispatch }) => {
    try {
      //   dispatch(isLoading(true));
      const response = await assignClassCalenderView(obj);
      dispatch(appointments(response?.data?.class_list));

      dispatch(isLoading(false));
      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const getClassDetails = (state) => state.class.classDetail;
export const getTotalStudentInClass = (state) => state.class.totalStudent;
export const FetchClassDetails = createAsyncThunk(
  "FetchClassDetails",
  async (obj, { dispatch }) => {
    try {
      //   dispatch(isLoading(true));
      const response = await assignClassDetails(obj);

      if (response.code === 1) {
        dispatch(classDetail(response?.data?.assigned_class_details));
      }
      dispatch(isLoading(false));
      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const getAllStudentOfAClass = (state) => state.class.allStudentOfAClass;
export const FetchStudentForClass = createAsyncThunk(
  "FetchStudentForClass",
  async (obj, { dispatch }) => {
    try {
      //   dispatch(isLoading(true));
      const response = await getAllClassStudents(obj);
      if (response?.code === 1) {
        dispatch(allStudentOfAClass(response?.data?.student_list));
        dispatch(totalStudentInClass(response?.data?.total_student));
      }

      dispatch(isLoading(false));
      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const getstudentDetail = (state) => state.class.studentDetail;
export const FetchStudentDetail = createAsyncThunk(
  "FetchStudentDetail",
  async (obj, { dispatch }) => {
    try {
      //   dispatch(isLoading(true));
      const response = await getStudentDetails(obj);
      if (response?.code === 1) {
        dispatch(studentDetail(response?.data));
      }

      dispatch(isLoading(false));
      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const FetchRegisterDetail = createAsyncThunk(
  "registerDetail",
  async (obj, { dispatch }) => {
    const response = await registerDetails(obj);
    if (response?.code === 1) {
      dispatch(registerDetail(response?.data?.register_details));
    }
    return response;
  }
);

export const FetchAllOtherStudents = createAsyncThunk(
  "AllOtherStudents",
  async (obj, { dispatch }) => {
    try {
      const response = await getAllOtherStudents(obj);
      if (response?.code === 1) {
        console.log(response);
        dispatch(otherStudentListingForClass(response?.data?.student_list));
      }
      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const FetchAllOtherStudentsForEvent = createAsyncThunk(
  "AllOtherStudentsForEvent",
  async (obj, { dispatch }) => {
    try {
      const response = await getAllOtherStudentsForEvent(obj);
      if (response?.code === 1) {
        console.log(response);
        dispatch(otherStudentListingForEvent(response?.data?.student_list));
      }
      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const studentListSelector = (state) => state.class.studentList;

export const FetchStudentList = createAsyncThunk(
  "FetchStudentList",
  async (obj, { dispatch }) => {
    try {
      const response = await studentList(obj);
      if (response?.code === 1) {
        dispatch(studentlisting(response?.data?.student_list));
        // dispatch(totalCount(response?.data?.total_student));
      }
      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const FetchEventList = createAsyncThunk(
  "FetchEventList",
  async (obj, { dispatch }) => {
    try {
      const response = await eventList(obj);
      if (response?.code === 1) {
        dispatch(eventListing(response?.data?.event_list));
        // dispatch(totalCount(response?.data?.total_student));
      }
      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const FetchRegisterList = createAsyncThunk(
  "FetchRegisterList",
  async (obj, { dispatch }) => {
    try {
      const response = await registerList(obj);
      if (response?.code === 1) {
        dispatch(registerListing(response?.data?.register_list));
        dispatch(registerCount(response?.data?.register_count));
      }
      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const FetchAllStudentsForClass = createAsyncThunk(
  "AllStudentsForClass",
  async (obj, { dispatch }) => {
    try {
      const response = await allStudentListForAClass(obj);
      if (response?.code === 1) {
        console.log(response);
        dispatch(allStudentForClass(response?.data?.student_list));
      }
      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const FetchAllStudentsForEvent = createAsyncThunk(
  "AllStudentsForEvent",
  async (obj, { dispatch }) => {
    try {
      const response = await allStudentListForAnEvent(obj);
      if (response?.code === 1) {
        console.log(response);
        dispatch(allStudentForEvent(response?.data?.student_list));
      }
      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export default ClassSlice.reducer;
