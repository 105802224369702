import React from "react";

function BookingCard({ type }) {
  return (
    <div className="bg-white p-3 rounded h-100 shadow">
      <div className="row">
        <div className="col-sm-6 col-md-12 col-lg-12 col-xl-8 mb-2 text-capitalize">
          <h4>kick boxing</h4>
          <p className="mb-2">adult | child | mixed</p>
          <p className="theme-color1 mb-2">individual class</p>
          <p className="mb-2">3017, airplane avenue, Arimo Idaho- 83214</p>
          <p className="mb-0">alison william</p>
        </div>
        <div className="col-sm-6 col-md-12 col-lg-12 col-xl-4 mb-2 position-relative text-sm-end">
          <p className="text-capitalize text-muted">On: 12 jan 2022</p>
          <div className="payment-type">
            <h3 className="theme-color">
              <i className="fa fa-gbp" aria-hidden="true"></i> 35
            </h3>
            <p className="text-capitalize theme-color fw-bold">net banking</p>
            {type === "new" && (
              <button className="btn btn-accept btn-view w-auto py-2 px-4">
                Cancel
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookingCard;
