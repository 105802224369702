import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 650 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 650, min: 0 },
    items: 1,
  },
};

const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
  const {
    carouselState: { currentSlide },
  } = rest;
  return (
    <div
      className="buttonGroup"
      style={{
        position: "absolute",
      }}
    >
      <button
        className={"slick-prev slick-arrow "}
        aria-label="Previous"
        type="button"
        onClick={() => previous()}
      >
        Previous
      </button>
      <button
        className="slick-next slick-arrow"
        aria-label="Next"
        type="button"
        onClick={() => next()}
      >
        Next
      </button>
    </div>
  );
};

function RegisterEvents() {
  return (
    <>
      <section className="mt-4">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
              <div className="btn-flex">
                <a href="#" className="white-btn shadow tanitems">
                  Make Bookings
                </a>
                <a href="#" className="white-btn shadow tanitems">
                  All Students
                </a>
                <a href="my-booking.html" className="white-btn shadow tanitems">
                  My Bookings
                </a>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
              <div className="view-btns justify-content-end">
                <span className="btn-accept btn-view w-auto">
                  Balance/Credit:{" "}
                  <i className="fa fa-gbp" aria-hidden="true"></i> 24
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sec-bg py-4 px-3 bottom-space">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-sm-12 col-md-12 col-lg-12 mt-3">
              <h3>Upcoming Special Events</h3>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 mt-3">
              <Carousel
                containerClass="student-container"
                responsive={responsive}
                // removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                infinite={true}
                showDots={false}
                arrows={false}
                itemClass="p-1"
                customButtonGroup={<ButtonGroup />}
                renderButtonGroupOutside={true}
              >
                <div className="">
                  <div className="class__details bg-white p-2 rounded h-100 position-relative w-100">
                    <div className="event-box-flex align-items-normal p-0">
                      <span>
                        <img
                          src="assets/img/kick.png"
                          className="event-img"
                          alt=""
                        />
                      </span>
                      <span>
                        <h6>karate</h6>
                        <p className="mb-0 fs-7">
                          <img
                            src="assets/img/address.png"
                            className=""
                            alt=""
                          />
                          livepool
                        </p>
                        <p className="mb-0 fs-7">
                          <img
                            src="assets/img/date-of-birth.png"
                            className=""
                            alt=""
                          />
                          17th feb 2022
                        </p>
                      </span>
                    </div>
                    <div className="view-btns justify-content-end">
                      <a href="#" className="left-seats py-1 px-2 fs-7">
                        <span>More Info</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="">
                  <div className="class__details bg-white p-2 rounded h-100 position-relative w-100">
                    <div className="event-box-flex align-items-normal p-0">
                      <span>
                        <img
                          src="assets/img/kick.png"
                          className="event-img"
                          alt=""
                        />
                      </span>
                      <span>
                        <h6>karate</h6>
                        <p className="mb-0 fs-7">
                          <img
                            src="assets/img/address.png"
                            className=""
                            alt=""
                          />
                          livepool
                        </p>
                        <p className="mb-0 fs-7">
                          <img
                            src="assets/img/date-of-birth.png"
                            className=""
                            alt=""
                          />
                          17th feb 2022
                        </p>
                      </span>
                    </div>
                    <div className="view-btns justify-content-end">
                      <a href="#" className="left-seats py-1 px-2 fs-7">
                        <span>More Info</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="">
                  <div className="class__details bg-white p-2 rounded h-100 position-relative w-100">
                    <div className="event-box-flex align-items-normal p-0">
                      <span>
                        <img
                          src="assets/img/kick.png"
                          className="event-img"
                          alt=""
                        />
                      </span>
                      <span>
                        <h6>karate</h6>
                        <p className="mb-0 fs-7">
                          <img
                            src="assets/img/address.png"
                            className=""
                            alt=""
                          />
                          livepool
                        </p>
                        <p className="mb-0 fs-7">
                          <img
                            src="assets/img/date-of-birth.png"
                            className=""
                            alt=""
                          />
                          17th feb 2022
                        </p>
                      </span>
                    </div>
                    <div className="view-btns justify-content-end">
                      <a href="#" className="left-seats py-1 px-2 fs-7">
                        <span>More Info</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="">
                  <div className="class__details bg-white p-2 rounded h-100 position-relative w-100">
                    <div className="event-box-flex align-items-normal p-0">
                      <span>
                        <img
                          src="assets/img/kick.png"
                          className="event-img"
                          alt=""
                        />
                      </span>
                      <span>
                        <h6>karate</h6>
                        <p className="mb-0 fs-7">
                          <img
                            src="assets/img/address.png"
                            className=""
                            alt=""
                          />
                          livepool
                        </p>
                        <p className="mb-0 fs-7">
                          <img
                            src="assets/img/date-of-birth.png"
                            className=""
                            alt=""
                          />
                          17th feb 2022
                        </p>
                      </span>
                    </div>
                    <div className="view-btns justify-content-end">
                      <a href="#" className="left-seats py-1 px-2 fs-7">
                        <span>More Info</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="">
                  <div className="class__details bg-white p-2 rounded h-100 position-relative w-100">
                    <div className="event-box-flex align-items-normal p-0">
                      <span>
                        <img
                          src="assets/img/kick.png"
                          className="event-img"
                          alt=""
                        />
                      </span>
                      <span>
                        <h6>karate</h6>
                        <p className="mb-0 fs-7">
                          <img
                            src="assets/img/address.png"
                            className=""
                            alt=""
                          />
                          livepool
                        </p>
                        <p className="mb-0 fs-7">
                          <img
                            src="assets/img/date-of-birth.png"
                            className=""
                            alt=""
                          />
                          17th feb 2022
                        </p>
                      </span>
                    </div>
                    <div className="view-btns justify-content-end">
                      <a href="#" className="left-seats py-1 px-2 fs-7">
                        <span>More Info</span>
                      </a>
                    </div>
                  </div>
                </div>
              </Carousel>
            </div>

            <div className="col-sm-12 col-md-12 col-lg-12">
              <hr />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 mt-3">
              <h3>Account Holder's Details</h3>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 mt-3">
              <div className="bg-white p-3 rounded expandible">
                <div className="row">
                  <div className="col-sm-6 col-md-4 col-lg-2 mb-2">
                    <p className="detail-account text-capitalize position-relative">
                      <img
                        src="assets/img/First-Name.png"
                        className="icon-title"
                        alt=""
                      />
                      <span>full name</span>
                      <span>
                        <b>thomas kors</b>
                      </span>
                    </p>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 mb-2">
                    <p className="detail-account position-relative">
                      <img
                        src="assets/img/email.png"
                        className="icon-title"
                        alt=""
                      />
                      <span className="text-capitalize">email ID</span>
                      <span>
                        <b>thomas.kors@gmail.com</b>
                      </span>
                    </p>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-3 mb-2">
                    <p className="detail-account position-relative">
                      <img
                        src="assets/img/First-Name.png"
                        className="icon-title"
                        alt=""
                      />
                      <span className="text-capitalize">username</span>
                      <span>
                        <b>thomas_1345</b>
                      </span>
                    </p>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <hr />
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-2 mb-2">
                    <p className="detail-account text-capitalize position-relative">
                      <img
                        src="assets/img/date-of-birth.png"
                        className="icon-title"
                        alt=""
                      />
                      <span>date of birth</span>
                      <span>
                        <b>13 aug 1986</b>
                      </span>
                    </p>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 mb-2">
                    <p className="detail-account text-capitalize position-relative">
                      <img
                        src="assets/img/address.png"
                        className="icon-title"
                        alt=""
                      />
                      <span className="">full address</span>
                      <span>
                        <b>3017, airplane avenue, arimo idaho- 83214</b>
                      </span>
                    </p>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-3 mb-2"></div>
                  <div className="col-sm-6 col-md-4 col-lg-3 mb-2">
                    <div className="view-btns justify-content-end">
                      <button className="btn btn-accept btn-view w-auto">
                        Cancel Account
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default RegisterEvents;
