import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { NavbarVariants } from "../constant/Variants.js";
import { FetchTeacherDetails, getTeacherInfo } from "../features/slice.js";
import { requestToEditProfile } from "../api/login.js";
import EditRequest from "./Modal/EditRequest.jsx";
import { toast } from "react-toastify";
import {
  FetchAreacode,
  FetchDiscipline,
} from "../features/student/StudentSlice.js";
import { postFormData } from "../api/index.js";

function Navbar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const list = useSelector((state) => state.notification.list);
  const data = useSelector(getTeacherInfo);
  const getNotification = useCallback(() => {}, []);
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (!data) {
      dispatch(FetchTeacherDetails());
    }
  }, [dispatch, data]);

  const handleEditRequest = () => {
    requestToEditProfile({}).then((res) => {
      if (res?.code === 1) {
        toast.success(res?.message);
        dispatch(FetchTeacherDetails());
      } else {
        toast.error(res?.message);
      }
    });
  };

  useEffect(() => {
    dispatch(FetchAreacode({}));
    dispatch(FetchDiscipline({}));
  }, [dispatch]);

  const handleSubscribeNewsletter = (subs) => {
    postFormData("user/subscribeNewsletter", {
      user_id: data?.id,
      status: !subs,
    }).then((res) => {
      if (res?.code === 1) {
        toast.success(res?.message);
        dispatch(FetchTeacherDetails());
      } else {
        toast.error(res?.message);
      }
    });
  };

  return (
    <div className="header-top-tab">
      <div className="">
        <img
          src="assets/img/headerlogo.png"
          className="logo-icon"
          alt="Book in Class"
          title="Book in Class"
          onClick={() => {
            navigate("/appointments", { replace: true });
          }}
        />
      </div>

      <nav className="navbar navbar-expand-lg navbar-light loginMenu ">
        <button
          className="btn btn-blue float-end menu-toggle-img d-lg-none collapsed"
          data-bs-target="#navbarNav"
          data-bs-toggle="collapse"
          aria-expanded="false"
        ></button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            <li className={"nav-item headerLinks"}>
              <Link
                className={
                  window.location.href?.includes("appointment")
                    ? "nav-link active fw-bold"
                    : "nav-link"
                }
                to={"/appointments"}
              >
                Calendar
              </Link>
            </li>
            <li className="nav-item headerLinks">
              <Link
                className={
                  window.location.href?.includes("register")
                    ? "nav-link active fw-bold"
                    : "nav-link"
                }
                to={"/register"}
              >
                Class Register and Attendance
              </Link>
            </li>
            <li className="nav-item headerLinks">
              <Link
                className={
                  window.location.href?.includes("student")
                    ? "nav-link active fw-bold"
                    : "nav-link"
                }
                to={"/students"}
              >
                Students
              </Link>
            </li>
          </ul>
        </div>
      </nav>
      <div className="noti-flex">
        <div className="notificatin-drop cursor-pointer">
          <img src="assets/img/notification.png" className="img-fluid" alt="" />
          <div className="notificatin-box teacher-notification">
            <h6>Notifications</h6>
            {list?.length > 0 ? (
              list?.map((item, i) => {
                return (
                  <div className="noti-text" key={i}>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                    <p className="day-time">
                      <i className="fa fa-clock-o" aria-hidden="true"></i>{" "}
                      12:00pm
                    </p>
                  </div>
                );
              })
            ) : (
              <div>No notifications.</div>
            )}

            <div className="text-center mt-4">
              <Link to="/notification" className="fw-bold">
                See All Notification
              </Link>
            </div>
          </div>
        </div>
        <div className="profile-drop cursor-pointer position-relative">
          <div
            className="tabs-list shadow"
            onClick={() => {
              // if (data?.canEditProfile) {
              //   navigate("/profile");
              // } else if (data?.canEditProfile === false) {
              //   toast.error(
              //     "Your profile edit request is pending. You can edit your profile once admin approves."
              //   );
              // } else {
              //   setShow(true);
              // }
            }}
          >
            <span>
              <img
                src={
                  data?.image?.includes("http")
                    ? data?.image
                    : "assets/img/headerlogo.png"
                }
                className="profile-img"
                alt=""
              />
            </span>
            <span>
              <h5 className="mb-0">{data?.full_name ? data?.full_name : ""}</h5>
            </span>
          </div>
          <div className="profile-collapse notificatin-box">
            {/* <div className="noti-text">
              <a href="payment-summary.html">
                <img src="assets/img/payment-summary.png" className="" alt="" />{" "}
                Payment Summary
              </a>
            </div>
            <div className="noti-text">
              <a href="my-membership.html">
                <img src="assets/img/my-membership.png" className="" alt="" />{" "}
                My Membership
              </a>
            </div> */}
            <div className="noti-text">
              <span
                onClick={() => {
                  navigate("/profile");

                  // if (data?.canEditProfile) {
                  //   navigate("/profile");
                  // } else if (data?.canEditProfile === false) {
                  //   toast.error(
                  //     "Your profile edit request is pending. You can edit your profile once admin approves."
                  //   );
                  // } else {
                  //   setShow(true);
                  // }
                }}
              >
                <img src="assets/img/password.png" className="" alt="" />{" "}
                {/* {data?.canEditProfile === null
                  ? "Request Profile Edit"
                  : data?.canEditProfile === false
                  ? "Request Pending"
                  : data?.canEditProfile === true
                  ? "Edit Profile"
                  : ""} */}
                Edit Profile
              </span>
            </div>

            <div className="noti-text">
              <Link to="/change-password">
                <img src="assets/img/password.png" className="" alt="" /> Change
                Password
              </Link>
            </div>
            <div className="noti-text setting-nav">
              <span className="">
                <img src="assets/img/newsletters.png" className="" alt="" />{" "}
                Newsletter
              </span>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={data?.isSubscribed}
                  onChange={() => {
                    handleSubscribeNewsletter(data?.isSubscribed);
                  }}
                />
                <span>
                  <strong></strong>
                </span>
              </label>
            </div>
            <div className="text-center mt-4">
              <span
                className="fw-bold"
                style={{
                  color: "#ff2f2f",
                }}
                onClick={async () => {
                  await localStorage.removeItem('token_t');
                  navigate("/", { replace: true });
                }}
              >
                Logout{" "}
                <img
                  src="assets/img/logout.png"
                  className=""
                  alt=""
                  width="18px"
                />
              </span>
            </div>
          </div>
        </div>
      </div>
      <EditRequest
        show={show}
        handleShow={() => {
          setShow(!show);
        }}
        confirm={handleEditRequest}
      />
    </div>
  );
}

export default Navbar;
