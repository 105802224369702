import React from "react";
import { useNavigate } from "react-router";

function BackButton() {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => {
        navigate(-1);
      }}
    >
      <i class="fa fa-arrow-left" aria-hidden="true"></i>
    </div>
  );
}

export default BackButton;
