import {
  getData,
  getDataforUrl,
  patchFormData,
  postFormData,
  putFormData,
} from "./index";
export const Login = async (payload) => {
  return postFormData("auth/login", payload).then((data) => {
    return data;
  });
};

export const VerifyOtp = async (payload) => {
  return postFormData("auth/verifyOtp", payload).then((data) => {
    return data;
  });
};

export const ResendOtp = async (payload) => {
  return getData("auth/resendOtp", payload).then((data) => {
    return data;
  });
};
export const forgotPassword = async (payload) => {
  return getData("auth/forgotPassword", payload).then((data) => {
    return data;
  });
};

export const resetPassword = async (payload) => {
  return putFormData("auth/resetPassword", payload).then((data) => {
    return data;
  });
};

export const changePassword = async (payload) => {
  return patchFormData("teacher/changePassword", payload).then((data) => {
    return data;
  });
};

export const getUserDetails = async (payload) => {
  return getData("user/getUserDetails", payload).then((data) => {
    return data;
  });
};

export const requestToEditProfile = async (payload) => {
  return postFormData("teacher/requestToEditProfile", payload).then((data) => {
    return data;
  });
};
