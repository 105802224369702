import * as Yup from "yup";

export const loginSchema = Yup.object({
  email: Yup.string().email("Kindly enter a valid email.").required("Required"),
  password: Yup.string()
    .matches(
      // eslint-disable-next-line no-useless-escape
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\.£€\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    )
    .required("Required"),
});

export const forgetPasswordSchema = Yup.object({
  email: Yup.string().email("Kindly enter a valid email.").required("Required"),
});
