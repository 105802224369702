import React, { useImperativeHandle } from "react";
import { useFormik } from "formik";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import * as Yup from "yup";
import moment from "moment";
import { getData } from "../../api";
import { useEffect, useState } from "react";
import Loader from "../Loader";

require("react-dom");
window.React2 = require("react");
console.log(window.React1 === window.React2);

const ChangeStudentClassComponent = ({
  show,
  handleShow,
  changeClassesForStudentData,
  class_type,
  id,
  submitting,
}) => {
  // const dispatch = useDispatch();
  const [lists, setLists] = useState([]);

  useEffect(() => {
    getData("admin/classList", { page: 1, limit: 1000000000 }).then((res) => {
      if (res?.code === 1) {
        setLists(res?.data?.class_list.filter((it) => it?.id !== id));
      }
    });
  }, [id]);
  // useImperativeHandle({})
  const formik = useFormik({
    initialValues: {
      selectedClass: "",
      selectedDate: "",
      availableTimeSlots: [],
      availableDates: [],
    },
    validationSchema: Yup.object({
      selectedClass: Yup.object().required("Required"),
      selectedDate: Yup.object().required("Required"),
    }),
    onSubmit: (values) => {
      console.log(values);
      changeClassesForStudentData(
        values.selectedClass?.id,
        values.selectedDate,
        values.selectedClass,
        values.start_time,
        values.end_time,
        values.time_slot_id
      );
    },
  });

  const handleDateAndTime = (classs) => {
    let arr = [...classs?.time_slot_list];
    let b = [];

    arr?.forEach((slots) => {
      slots?.time_slot?.forEach((times) => {
        const index = b.indexOf(times);
        if (index === -1) {
          b.push(times);
        }
      });
    });
    console.log(b);

    b.filter(function (item, pos, a) {
      return (
        a.findIndex(
          (it) =>
            it?.date === item?.date &&
            it?.time_slot_id === item?.time_slot_id &&
            it?.start_time === item?.start_time &&
            it?.end_time === item?.end_time
        ) === pos
      );
    })?.sort(function (a, b) {
      return moment(b.date, "yyyy-mm-dd") - moment(a.date, "yyyy-mm-dd");
    });

    formik.setFieldValue("availableDates", b);
  };

  return (
    <Modal show={show} onHide={handleShow} size="lg" centered>
      <div className=" modal-dialog-centered">
        <div className="modal-content body-bg">
          <div className="modal-header border-0 justify-content-center">
            <h5>Change Class</h5>
            <button
              type="button"
              className="btn-close"
              onClick={handleShow}
            ></button>
          </div>
          <div className="modal-body mb-4 p-4">
            <form className="form-style" onSubmit={formik.handleSubmit}>
              <label className="fw-bold">Select Class</label>

              <Select
                // isMulti
                options={lists?.length > 0 ? lists : undefined}
                getOptionLabel={(option) => option?.name}
                getOptionValue={(option) => option?.id}
                className="mb-4"
                value={formik.values.selectedClass}
                onChange={(val) => {
                  formik.setFieldValue("selectedClass", val);
                  handleDateAndTime(val);
                }}
              />
              {formik.errors.selectedClass && formik.touched.selectedClass && (
                <span className="text-danger">
                  {formik.errors.selectedClass}
                </span>
              )}

              <div className="">
                <div className="">
                  <label className="fw-bold">Select Time Slot</label>

                  <Select
                    // isMulti
                    options={formik.values.availableDates}
                    getOptionLabel={(option) =>
                      `${moment(option?.start_time, "hh:mm:ss").format(
                        "hh:mm a"
                      )}-${moment(option?.end_time, "hh:mm:ss").format(
                        "hh:mm a"
                      )}, ${moment(option?.date).format("DD MMM YYYY")}`
                    }
                    getOptionValue={(option) => option}
                    className="w-auto"
                    value={formik.values.selectedDate}
                    // styles={customStyles}
                    onChange={(val) => {
                      formik.setFieldValue("selectedDate", val);
                    }}
                  />
                  {formik.errors.selectedDate &&
                    formik.touched.selectedDate && (
                      <span className="text-danger">
                        {formik.errors.selectedDate}
                      </span>
                    )}
                </div>
              </div>

              <div className="text-center mt-4">
                <button
                  type="submit"
                  className="btn btn-accept btn-view mt-2"
                  disabled={submitting}
                >
                  {submitting ? <Loader /> : "Add"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ChangeStudentClassComponent;
