import { Formik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { uploadFile } from "react-s3";
import * as Yup from "yup";
import { FetchTeacherDetails, getTeacherInfo } from "../slice";
import { config } from "./../../utils/index";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import { toast } from "react-toastify";
import { editProfile } from "../../api/teacher";
import Loader from "./../../component/Loader";
import CreatableSelectInput from "../../component/CreatableSelectField/CreatableSelectInput";
import PhoneInput, { formatPhoneNumberIntl } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import moment from "moment/moment";
import { isValidPhoneNumber } from "react-phone-number-input";
window.Buffer = window.Buffer || require("buffer").Buffer;

const getYears = () => {
  let startYear = 1970;
  let endYear = moment().get("y");
  let datesArray = [];
  while (startYear <= endYear) {
    datesArray.unshift(startYear);
    startYear++;
  }
  return datesArray;
};

export const months = [
  {
    id: 1,
    value: "January",
  },
  {
    id: 2,
    value: "February",
  },
  {
    id: 3,
    value: "March",
  },
  {
    id: 4,
    value: "April",
  },
  {
    id: 5,
    value: "May",
  },
  {
    id: 6,
    value: "June",
  },
  {
    id: 7,
    value: "July",
  },
  {
    id: 8,
    value: "August",
  },
  {
    id: 9,
    value: "September",
  },
  {
    id: 10,
    value: "October",
  },
  {
    id: 11,
    value: "November",
  },
  {
    id: 12,
    value: "December",
  },
];
function EditProfile() {
  const dispatch = useDispatch();
  const data = useSelector(getTeacherInfo);
  const [time, setTime] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [yearList, setYearList] = useState([]);

  const getDetails = useCallback(() => {
    dispatch(FetchTeacherDetails());
  }, [dispatch]);

  useEffect(() => {
    getDetails();
  }, [getDetails]);

  useEffect(() => {
    let interval;
    if (isActive && time < 100) {
      interval = setInterval(() => {
        // if (time < 100)
        setTime((seconds) => (seconds < 99 ? seconds + 1 : seconds));
      }, 10);
    }
    return () => clearInterval(interval);
  }, [isActive, time]);
  const years = getYears();

  const handleImage = (e, setValue) => {
    const { files } = e.target;
    setIsActive(true);

    uploadFile(files[0], config)
      .then((data) => {
        setValue("image", data?.location);
        setTime(100);
        setIsActive(true);
        setTimeout(() => {
          setTime(0);
          setIsActive(false);
        }, 2000);
      })
      .catch((err) => console.error(err));
  };

  const isValid = (val, setFieldError) => {
    console.log(val, "Value");
    if (val && !isValidPhoneNumber(val)) {
      setFieldError("mobile", "Invalid Number");
      return false;
    } else {
      setFieldError("mobile", "");
    }
    return true;
  };

  useEffect(() => {
    if (yearList?.length === 0) {
      let minYear = moment().subtract(4, "years").format("YYYY");
      let years = [];
      for (let i = 1920; i <= minYear; i++) {
        years.push(i?.toString());
      }
      years.sort((a, b) => b - a);
      setYearList(years);
    }
  }, [yearList?.length]);

  if (!!!data) {
    return (
      <section className="sec-bg h-100">
        <div className="container h-100">
          <div className="row h-100">
            <div className="col-sm-12 h-100">
              <Loader />
            </div>
          </div>
        </div>
      </section>
    );
  } else {
    return (
      <section className="sec-bg py-4 px-3">
        <div className="container">
          <div className="form-style form-mw">
            <Formik
              initialValues={{
                full_name: data?.full_name || "",
                email: data?.email || "",
                // countryCode: data?.countryCode || "+91",
                mobile: data?.mobile
                  ? `${data?.countryCode}${data?.mobile}`
                  : "",
                highest_qualification:
                  data?.qualification_list?.map((it) => {
                    return {
                      label: it?.qualification,
                      value: it?.qualification,
                    };
                  }) || [],
                expertise_in_subject:
                  data?.subject_list?.map((it) => {
                    return { label: it?.subject, value: it?.subject };
                  }) || [],
                award_name:
                  data?.award_list?.map((it) => {
                    return { label: it?.award, value: it?.award };
                  }) || [],
                document: data?.document_list?.map((it) => it?.document) || [],
                image: data?.image || "",
                property_number: data?.address?.property_number || "",
                property_name: data?.address?.property_name || "",
                street_number: data?.address?.street_number || "",
                town: data?.address?.town || "",
                country: data?.address?.country || "",
                postal_code: data?.address?.postal_code || "",
                // dob: data?.address?.dob || "",
                day: data?.address?.dob_day || "",
                month: data?.address?.dob_month || "",
                year: data?.address?.dob_year || "",
              }}
              enableReinitialize={true}
              validationSchema={Yup.object({
                email: Yup.string()
                  .email("Kindly enter a valid email.")
                  .required("Required"),
                full_name: Yup.string().required("Required"),
                expertise_in_subject: Yup.array(),
                award_name: Yup.array(),
                highest_qualification: Yup.array(),
                mobile: Yup.number()
                  .required("Required")
                  .positive("Kindly enter a valid number."),
                // countryCode: Yup.number()
                //   .required("Required")
                //   .positive("Kindly enter a valid number."),
                document: Yup.array().notRequired(),
                image: Yup.string(),
                property_number: Yup.string().required("Required"),
                street_number: Yup.string().required("Required"),
                town: Yup.string().required("Required"),
                country: Yup.string().required("Required"),
                postal_code: Yup.string().required("Required"),

                // dob: Yup.string().required("Required"),
                day: Yup.string().required("Required"),
                month: Yup.string().required("Required"),
                year: Yup.string().required("Required"),
              })}
              onSubmit={(values) => {
                const countryCode = values?.mobile
                  ? formatPhoneNumberIntl(values?.mobile).split(" ")[0]
                  : "";
                const payload = {
                  full_name: values.full_name,
                  email: values.email,
                  mobile: values?.mobile
                    ? values?.mobile?.replace(countryCode, "")
                    : "",
                  countryCode: countryCode,
                  image: values.image,

                  awards: JSON.stringify(
                    values.award_name?.map((it) => it.value)
                  ),
                  qualifications: JSON.stringify(
                    values.highest_qualification?.map((it) => it.value)
                  ),
                  expertise_in_subjects: JSON.stringify(
                    values.expertise_in_subject?.map((it) => it.value)
                  ),
                  document: JSON.stringify(values.document),
                  property_number: values?.property_number || "",
                  property_name: values?.property_name || "",
                  street_number: values?.street_number || "",
                  town: values?.town || "",
                  country: values?.country || "",
                  postal_code: values?.postal_code || "",
                  // dob: values?.dob || "",
                  day: values?.day || "",
                  month: values?.month || "",
                  year: values?.year || "",
                };
                setLoading(true);
                editProfile(payload).then((res) => {
                  setLoading(false);
                  if (res?.code === 1) {
                    toast.success(res?.message);
                  } else {
                    toast.error(res?.message);
                  }
                });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                setFormikState,
                setFieldError,
              }) => (
                <form
                  className="row align-items-center"
                  onSubmit={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleSubmit(e);
                  }}
                >
                  <div className="form-group text-center col-sm-12 col-md-12 mb-4">
                    <h2 className="mdl-ttl">Edit Profile</h2>
                  </div>
                  <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-5">
                    <h3>Personal Info</h3>
                  </div>
                  <div className="form-group col-sm-12 col-md-12 text-center col-lg-12 mb-5">
                    <div className="upload-btn-wrapper editPrf">
                      <button className="btn">
                        {time > 0 && isActive ? (
                          <Progress type="circle" width={50} percent={time} />
                        ) : (
                          <img
                            src={values?.image || "assets/img/employe.png"}
                            alt=""
                          />
                        )}

                        {time > 0 && isActive ? null : (
                          <div className="camera-icon">
                            <i className="fa fa-camera"></i>
                          </div>
                        )}
                      </button>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                          if (e.target.files.length > 0) {
                            handleImage(e, setFieldValue);
                          }
                        }}
                      />
                    </div>
                    {errors.image && touched.image && (
                      <div className="text-red">{errors.image}</div>
                    )}
                  </div>
                  <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-5">
                    <label className="fw-bold">Full Name</label>
                    <div className="input-container">
                      <input
                        type="text"
                        className="form-control ps-4 h-auto"
                        placeholder="Full Name"
                        name="full_name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.full_name}
                      />
                      <img
                        alt=""
                        src="assets/img/First-Name.png"
                        className="input-img"
                      />
                    </div>
                    {errors.full_name && touched.full_name && (
                      <div className="text-red">{errors.full_name}</div>
                    )}
                  </div>
                  <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-5">
                    <label className="fw-bold">Email</label>
                    <div className="input-container">
                      <input
                        type="email"
                        className="form-control ps-4 h-auto"
                        placeholder="Email Address"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />
                      <img
                        alt=""
                        src="assets/img/First-Name.png"
                        className="input-img"
                      />
                    </div>
                    {errors.email && touched.email && (
                      <div className="text-red">{errors.email}</div>
                    )}
                  </div>
                  <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-5">
                    <label className="fw-bold">Mobile</label>
                    <div className="input-container">
                      <PhoneInput
                        country="gb"
                        inputProps={{
                          name: "mobile",
                        }}
                        value={values?.mobile ? values.mobile : undefined}
                        onChange={(phone, country, e, formattedValue) => {
                          if (phone.length > 0) {
                            const raw = phone.slice(country?.dialCode?.length);
                            setFieldValue("mobile", raw);
                          } else {
                            setFieldValue("mobile", "");
                          }
                        }}
                        onBlur={() => {
                          isValid(`${values.mobile}`, setFieldError);
                        }}
                        placeholder="Mobile Number"
                        className="form-control signupClass ps-3"
                        // inputClass="phoneInputClass"
                      />
                      {/* <img
                        alt=""
                        src="assets/img/mobile.png"
                        className="input-img"
                      /> */}
                    </div>
                    {errors.mobile && touched.mobile && (
                      <div className="text-red">{errors.mobile}</div>
                    )}
                  </div>
                  <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-5">
                    <h3>Qualification Info</h3>
                  </div>
                  <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-5">
                    <label className="fw-bold">Highest Qualification</label>
                    <div className="input-container">
                      <CreatableSelectInput
                        className="form-control ps-4 py-2"
                        name="highest_qualification"
                        handleChange={(val) => {
                          setFieldValue("highest_qualification", val);
                        }}
                        value={values.highest_qualification || []}
                        onBlur={handleBlur}
                        placeholder="Type Qualifications and press tab to add"
                      />
                      <img
                        src="assets/img/edu-black.png"
                        className="input-img"
                        alt=""
                      />
                    </div>
                    {errors.highest_qualification &&
                      touched.highest_qualification && (
                        <div className="text-red">
                          {errors.highest_qualification}
                        </div>
                      )}
                  </div>
                  <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-5">
                    <label className="fw-bold">Expertise</label>
                    <div className="input-container">
                      {/* <input
                      className="form-control ps-4 h-auto"
                      placeholder="Expertise in Subject"
                      name="expertise_in_subject"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.expertise_in_subject}
                    /> */}
                      <CreatableSelectInput
                        className="form-control ps-4 py-2"
                        name="expertise_in_subject"
                        handleChange={(val) => {
                          setFieldValue("expertise_in_subject", val);
                        }}
                        value={values.expertise_in_subject}
                        onBlur={handleBlur}
                        placeholder="Type Subject Expertise and press tab to add"
                      />
                      <img
                        src="assets/img/expertise.png"
                        className="input-img"
                        alt=""
                      />
                    </div>
                    {errors.expertise_in_subject &&
                      touched.expertise_in_subject && (
                        <div className="text-red">
                          {errors.expertise_in_subject}
                        </div>
                      )}
                  </div>
                  <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-5">
                    <label className="fw-bold">Award Name</label>
                    <div className="input-container">
                      {/* <input
                      type="text"
                      className="form-control ps-4 h-auto"
                      placeholder="Award Name"
                      name="award_name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.award_name}
                    /> */}

                      <CreatableSelectInput
                        className="form-control ps-4 py-2"
                        name="award_name"
                        handleChange={(val) => {
                          setFieldValue("award_name", val);
                        }}
                        value={values.award_name}
                        onBlur={handleBlur}
                        placeholder="Type Award Name and press tab to add"
                      />
                      <img
                        alt=""
                        src="assets/img/award.png"
                        className="input-img"
                      />
                    </div>
                    {errors.award_name && touched.award_name && (
                      <div className="text-red">{errors.award_name}</div>
                    )}
                  </div>
                  <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-5">
                    <h3>Address Info</h3>
                  </div>

                  <div className="col-sm-6 col-md-6 mb-4">
                    <div className="form-group">
                      <label className="fw-bold">Property Number</label>
                      {errors?.address?.property_number &&
                        touched?.address?.property_number && (
                          <span className="text-red ms-1">
                            {errors?.address?.property_number}
                          </span>
                        )}
                      <div className="input-container">
                        <input
                          type="text"
                          className="form-control ps-4 h-auto"
                          placeholder="Property Number"
                          name="property_number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.property_number}
                        />

                        <img
                          src="assets/img/address.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 mb-4">
                    <div className="form-group">
                      <label className="fw-bold">Property Name</label>
                      {errors?.address?.property_name &&
                        touched?.address?.property_name && (
                          <span className="text-red ms-1">
                            {errors?.address?.property_name}
                          </span>
                        )}
                      <div className="input-container">
                        <input
                          type="text"
                          className="form-control ps-4 h-auto"
                          placeholder="Property Name"
                          name="property_name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.property_name}
                        />

                        <img
                          src="assets/img/address.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 mb-4">
                    <div className="form-group">
                      <label className="fw-bold">Street Name</label>
                      {errors?.address?.street_number &&
                        touched?.address?.street_number && (
                          <span className="text-red ms-1">
                            {errors?.address?.street_number}
                          </span>
                        )}
                      <div className="input-container">
                        <input
                          type="text"
                          className="form-control ps-4 h-auto"
                          placeholder="Street Name"
                          name="street_number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.street_number}
                        />

                        <img
                          src="assets/img/address.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 mb-4">
                    <div className="form-group">
                      <label className="fw-bold">Town</label>
                      {errors?.address?.town && touched?.address?.town && (
                        <span className="text-red ms-1">
                          {errors?.address?.town}
                        </span>
                      )}
                      <div className="input-container">
                        <input
                          type="text"
                          className="form-control ps-4 h-auto"
                          placeholder="Town"
                          name="town"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.town}
                        />

                        <img
                          src="assets/img/address.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 mb-4">
                    <div className="form-group">
                      <label className="fw-bold">Country</label>
                      {errors?.address?.country &&
                        touched?.address?.country && (
                          <span className="text-red ms-1">
                            {errors?.address?.country}
                          </span>
                        )}
                      <div className="input-container">
                        <select
                          className="form-control ps-4 h-auto"
                          placeholder="Country"
                          name="country"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.country}
                        >
                          <option value="">Select Country</option>
                          <option value="U.K">U.K</option>
                        </select>

                        <img
                          src="assets/img/address.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 mb-4">
                    <div className="form-group">
                      <label className="fw-bold">Postal Code</label>
                      {errors?.address?.postal_code &&
                        touched?.address?.postal_code && (
                          <span className="text-red ms-1">
                            {errors?.address?.postal_code}
                          </span>
                        )}
                      <div className="input-container">
                        <input
                          type="text"
                          className="form-control ps-4 h-auto"
                          placeholder="Postal Code"
                          name="postal_code"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.postal_code}
                        />

                        <img
                          src="assets/img/address.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-12 col-md-12 mb-2">
                    <label className="fw-bold">Date of Birth</label>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                    <div className="form-group">
                      <label className="fw-bold">Year</label>

                      <div className="input-container">
                        <select
                          className="form-control ps-4 h-auto"
                          placeholder="Year"
                          name="year"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.year}
                        >
                          <option value="">Select Year</option>
                          {yearList?.map((item) => {
                            return (
                              <option value={item} key={item}>
                                {item}
                              </option>
                            );
                          })}
                        </select>

                        <img
                          src="assets/img/address.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                    </div>
                    {errors?.address?.year && touched?.address?.year && (
                      <span className="text-red ms-1">
                        {errors?.address?.year}
                      </span>
                    )}
                  </div>

                  <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                    <div className="form-group">
                      <label className="fw-bold">Month</label>

                      <div className="input-container">
                        <select
                          className="form-control ps-4 h-auto"
                          placeholder="Month"
                          name="month"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.month}
                        >
                          <option value="">Select Month</option>
                          {months?.map((item, i) => (
                            <option value={item?.id}>{item?.value}</option>
                          ))}
                        </select>

                        <img
                          src="assets/img/address.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                    </div>
                    {errors?.address?.month && touched?.address?.month && (
                      <span className="text-red ms-1">
                        {errors?.address?.month}
                      </span>
                    )}
                  </div>

                  <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                    <div className="form-group">
                      <label className="fw-bold">Day</label>

                      <div className="input-container">
                        <select
                          className="form-control ps-4 h-auto"
                          placeholder="Select Day"
                          name="day"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.day}
                        >
                          <option value="">Select Day</option>
                          {[
                            ...Array(values.month === "2" ? 28 : 31).keys(),
                          ].map((item) => {
                            return <option value={item + 1}>{item + 1}</option>;
                          })}
                        </select>

                        <img
                          src="assets/img/address.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                    </div>
                    {errors?.address?.month && touched?.address?.month && (
                      <span className="text-red ms-1">
                        {errors?.address?.month}
                      </span>
                    )}
                  </div>

                  <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-5">
                    <label>
                      <img src="assets/img/document.png" className="" alt="" />{" "}
                      Upload Document
                    </label>
                  </div>

                  {errors.document && touched.document && (
                    <div className="text-red">{errors.document}</div>
                  )}
                  <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-5">
                    <div className="row">
                      <div className="form-group col-6 col-sm-6 col-md-6 col-lg-4 mb-4">
                        <div className="upload-btn-wrapper">
                          <button className="btn">
                            {time > 0 && isActive ? (
                              <Progress
                                type="circle"
                                width={50}
                                percent={time}
                              />
                            ) : (
                              <img
                                src={"assets/img/upload-doc.png"}
                                alt=""
                                className="rounded-0"
                              />
                            )}
                          </button>
                          <input
                            type="file"
                            name="myfile"
                            onChange={(e) => {
                              const { files } = e.target;
                              setIsActive(true);

                              uploadFile(files[0], config)
                                .then((data) => {
                                  let arr = [...values.document];
                                  arr.push(data?.location);
                                  setFieldValue("document", arr);
                                  setTime(100);
                                  setIsActive(true);
                                  setTimeout(() => {
                                    setTime(0);
                                    setIsActive(false);
                                  }, 2000);
                                })
                                .catch((err) => console.error(err));
                            }}
                          />
                        </div>
                      </div>
                      {values?.document?.map((item, i) => (
                        <div
                          className="form-group col-6 col-sm-6 col-md-6 col-lg-4 mb-4"
                          key={i}
                        >
                          <div className="upload-btn-wrapper">
                            <button className="btn">
                              <img
                                src={
                                  item?.includes("http")
                                    ? "assets/img/file.png"
                                    : "assets/img/upload-doc.png"
                                }
                                alt=""
                                className="rounded-0"
                              />
                            </button>
                            <a href={item} className="">
                              <p className="text-center">
                                {item?.split(".com/")?.[1]}
                              </p>
                            </a>
                            <span className="remove-img">
                              <img
                                alt=""
                                src="assets/img/remove.png"
                                onClick={() => {
                                  let arr = [...values.document];
                                  arr.splice(i, 1);
                                  setFormikState((prev) => {
                                    return {
                                      ...prev,
                                      values: {
                                        ...prev.values,
                                        document: arr,
                                      },
                                    };
                                  });
                                }}
                              />
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="form-group col-sm-12 col-md-12 text-center">
                    <button
                      type="submit"
                      className="btn btn-submit"
                      disabled={loading}
                    >
                      {loading ? <Loader /> : "Save Changes"}
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </section>
    );
  }
}

export default EditProfile;
