import React, { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import WeekStrip from "react-weekstrip-daypicker";
import moment from "moment";
import { FetchAssignedCall, FetchScheduledCall } from "../class/ClassSlice";
import { acceptRejectClass } from "../../api/class";
import { toast } from "react-toastify";

function Records() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classList = useSelector((state) => state.class.classList);
  const scheduledClass = useSelector((state) => state.class.scheduledClass);
  const studentList = useSelector((state) => state.class.studentList);
  const [show, setShow] = useState(false);
  const [date, setDate] = useState("");
  const handleShow = () => {
    setShow(!show);
  };

  const getList = useCallback(() => {
    dispatch(
      FetchAssignedCall({
        page: 1,
        limit: 10,
        date: date,
        status: 0,
      })
    );
    dispatch(
      FetchScheduledCall({
        page: 1,
        limit: 10,
        date: date,
        status: 1,
      })
    );
  }, [dispatch, date]);

  useEffect(() => {
    getList();
  }, [getList]);

  const handleAcceptReject = (data) => {
    acceptRejectClass(data).then((res) => {
      if (res?.code === 1) {
        toast?.success(res?.message);
      } else {
        toast?.error(res?.message);
      }
      getList();
    });
  };

  return (
    <section>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3 mb-3">
            <div className="sec-bg py-4 px-3 record-listing h-100">
              <h3 className="sec-title text-center fs-4 mb-3">
                Scheduled Classes
              </h3>
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12 mt-3">
                  {scheduledClass?.length > 0 ? (
                    scheduledClass?.map((item, i) => {
                      return (
                        <div
                          key={i}
                          className="bg-white p-2 scheduleClassCard rounded mb-2 shadow position-relative"
                        >
                          <span className="class-date">15 jan</span>
                          <div className="event-box-flex">
                            <span>
                              <img
                                src={
                                  item?.class_details?.image?.includes("http")
                                    ? item?.class_details?.image
                                    : "assets/img/headerlogo.png"
                                }
                                className="event-img"
                                alt=""
                              />
                            </span>
                            <span>
                              <h5>{item?.class_details?.name}</h5>
                              <p className="mb-0">
                                {" "}
                                {item?.class_details?.time_slot_list?.[0]
                                  ?.time_slot_for === "both"
                                  ? `Adult | Child`
                                  : item?.class_details?.time_slot_list?.[0]
                                      ?.time_slot_for}
                              </p>
                              <p className="theme-color1 mb-0">
                                {item?.class_details?.school_details?.name}
                              </p>
                              <p className="mb-0">
                                <img
                                  src="assets/img/address.png"
                                  className=""
                                  alt=""
                                />{" "}
                                {item?.class_details?.school_details?.address}
                              </p>
                            </span>
                          </div>
                          <div className="joining-flex">
                            {item?.students.length > 0 ? (
                              <span>
                                {item?.students.map((item) => {
                                  return (
                                    <img
                                      src={
                                        item?.image?.includes("http")
                                          ? item?.image
                                          : "assets/img/photo.png"
                                      }
                                      className="ppl-img"
                                      alt=""
                                    />
                                  );
                                })}
                                <small>
                                  {item?.booking_count}{" "}
                                  {item?.students.length > 1
                                    ? "people"
                                    : "person"}{" "}
                                  {item?.students.length > 1 ? "have" : "has"}{" "}
                                  joined
                                </small>
                              </span>
                            ) : (
                              <span></span>
                            )}
                            <Link
                              to={`/class/${item?.id}`}
                              className="left-seats cursor-pointer"
                            >
                              <span>View</span>
                            </Link>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div>No class scheduled</div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3">
            <div className="sec-bg py-4 px-3 record-listing h-100">
              <h3 className="sec-title text-center fs-4 mb-3">
                assigned classes
              </h3>

              <div className="row">
                {classList?.length > 0 ? (
                  classList?.map((item, i) => {
                    return (
                      <div
                        key={i}
                        className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3"
                      >
                        <div className="teacher-assigned bg-white p-3 rounded h-100 shadow position-relative">
                          <span className="class-date">
                            <img
                              src="assets/img/address.png"
                              className=""
                              alt=""
                            />{" "}
                            {item?.class_details?.school_details?.address}
                          </span>
                          <div className="event-box-flex">
                            <span>
                              <img
                                src={
                                  item?.class_details?.image?.includes("http")
                                    ? item?.class_details?.image
                                    : "assets/img/headerlogo.png"
                                }
                                className="event-img"
                                alt=""
                              />
                            </span>
                            <span>
                              <h5>
                                <span>{item?.class_details?.name}</span>
                              </h5>
                              <p className="mb-0">
                                {item?.class_details?.time_slot_list?.[0]
                                  ?.time_slot_for === "both"
                                  ? `Adult | Child`
                                  : item?.class_details?.time_slot_list?.[0]
                                      ?.time_slot_for}
                              </p>
                              <p className="theme-color1 mb-0">
                                {item?.class_details?.school_details?.name}
                              </p>
                            </span>
                          </div>
                          <div className="joining-flex">
                            {item?.booking_count > 0 ? (
                              <span>
                                <img
                                  src={"assets/img/photo.png"}
                                  className="ppl-img"
                                  alt=""
                                />
                                <img
                                  src="assets/img/photo.png"
                                  className="ppl-img"
                                  alt=""
                                />
                                <small>
                                  {item?.booking_count} people are joining
                                </small>
                              </span>
                            ) : (
                              <span></span>
                            )}
                            <div className="assignedClassRecords">
                              <button
                                className="left-seats left-seats-trans cursor-pointer fw-bold assignedClassRecordButton"
                                onClick={() => {
                                  handleAcceptReject({
                                    assign_class_id: item?.id,
                                    status: 2,
                                  });
                                }}
                              >
                                Reject
                              </button>
                              <button
                                className="left-seats cursor-pointer fw-bold assignedClassRecordButton"
                                onClick={() => {
                                  handleAcceptReject({
                                    assign_class_id: item?.id,
                                    status: 1,
                                  });
                                }}
                              >
                                Accept
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div>No Assigned Class.</div>
                )}
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3 mb-3">
            <div className="sec-bg py-4 px-3 record-listing h-100">
              <h6 className="my-access">My Access</h6>
              <div className="text-center my-3 position-relative">
                <div className="teacher-date-calender calender-date text-uppercase">
                  <WeekStrip
                    onChange={(val) => {
                      setDate(val.format("YYYY-MM-DD"));
                    }}
                    onWeekChange={(val) => {
                      setDate(val.format("YYYY-MM-DD"));
                    }}
                  />
                </div>
              </div>
              <h6 className="mt-5">Student Details</h6>
              <div className="bg-white p-2 text-capitalize mt-3">
                <div
                  className="accordion Student-Details-colles"
                  id="accordionExample"
                >
                  {scheduledClass?.length > 0 ? (
                    scheduledClass?.map((el) => {
                      return el.students?.map((item, i) => {
                        return (
                          <div className="accordion-item" key={i}>
                            <h2 className="accordion-header" id="headingOne">
                              <button
                                className="accordion-button"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseOne"
                                aria-expanded="true"
                                aria-controls="collapseOne"
                              >
                                <p className="student_detail-account position-relative">
                                  <img
                                    src="assets/img/First-Name.png"
                                    className="icon-title"
                                    alt=""
                                  />
                                  <span>student name</span>
                                  <span>
                                    <b>
                                      {item?.first_name
                                        ? `${item?.first_name} ${item?.last_name}`
                                        : ""}
                                    </b>
                                  </span>
                                </p>
                              </button>
                            </h2>
                            <div
                              id="collapseOne"
                              className="accordion-collapse collapse show"
                              aria-labelledby="headingOne"
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body">
                                <p className="student_detail-account position-relative">
                                  <img
                                    src="assets/img/address.png"
                                    className="icon-title"
                                    alt=""
                                  />
                                  <span className="">full address</span>
                                  <span>
                                    <b>{item?.address}</b>
                                  </span>
                                </p>
                                <p className="student_detail-account position-relative">
                                  <img
                                    src="assets/img/membership-status.png"
                                    className="icon-title"
                                    alt=""
                                  />
                                  <span className="">membership status</span>
                                  <span>
                                    <b>active</b>
                                  </span>
                                </p>
                                <p className="student_detail-account position-relative">
                                  <img
                                    src="assets/img/membership-status.png"
                                    className="icon-title"
                                    alt=""
                                  />
                                  <span className="">membership level</span>
                                  <span>
                                    <b>{item?.student_type}</b>
                                  </span>
                                </p>
                                <p className="student_detail-account position-relative">
                                  <img
                                    src="assets/img/date-of-birth.png"
                                    className="icon-title"
                                    alt=""
                                  />
                                  <span className="">date of birth</span>
                                  <span>
                                    <b>
                                      {moment(item?.dob).format("DD MMM YYYY")}
                                    </b>
                                  </span>
                                </p>
                                <p className="student_detail-account position-relative">
                                  <img
                                    src="assets/img/discipline.png"
                                    className="icon-title"
                                    alt=""
                                  />
                                  <span className="">discipline</span>
                                  <span>
                                    <b>{item?.discipline}</b>
                                  </span>
                                </p>
                                <hr />
                                <p className="student_detail-account position-relative">
                                  <img
                                    src="assets/img/First-Name.png"
                                    className="icon-title"
                                    alt=""
                                  />
                                  <span className="">account holder</span>
                                  <span>
                                    <b>
                                      {" "}
                                      {item?.parent_details?.first_name
                                        ? `${item?.parent_details?.first_name} ${item?.parent_details?.last_name}`
                                        : ""}
                                    </b>
                                  </span>
                                </p>
                                <p className="student_detail-account teacher-student position-relative">
                                  <img
                                    src="assets/img/mobile.png"
                                    className="icon-title"
                                    alt=""
                                  />
                                  <span className="">
                                    emergency contact number
                                  </span>
                                  <span>
                                    <b>
                                      {" "}
                                      {item?.emergency_contactNo
                                        ? `${item?.emergency_countryCode}${item?.emergency_contactNo}`
                                        : "NA"}
                                    </b>
                                  </span>
                                </p>
                                {item?.medical_note && <hr />}
                                {item?.medical_note && (
                                  <p className="student_detail-account teacher-student position-relative">
                                    <img
                                      src="assets/img/medical.png"
                                      className="icon-title"
                                      alt=""
                                    />
                                    <span className="">medical note</span>
                                    <span>{item?.medical_note}</span>
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      });
                    })
                  ) : (
                    <div>No data found.</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Records;
