import React, { useState } from "react";
import BookingCard from "./../../component/BookingCard";

function Bookings() {
  const [type, settype] = useState("new");
  return (
    <section className="sec-bg py-4 px-3 bottom-space">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-sm-12 col-md-12 col-lg-12 mt-3 text-center">
            <h3>My Bookings</h3>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-8 mt-3">
            <ul
              className="nav nav-pills booking-tabs mt-4"
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="pills-new-tab"
                  onClick={() => {
                    settype("new");
                  }}
                  type="button"
                  role="tab"
                  aria-controls="pills-new"
                  aria-selected="true"
                >
                  new
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-completed-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-completed"
                  type="button"
                  role="tab"
                  aria-controls="pills-completed"
                  aria-selected="false"
                >
                  completed
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-canceled-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-canceled"
                  type="button"
                  role="tab"
                  aria-controls="pills-canceled"
                  aria-selected="false"
                >
                  canceled
                </button>
              </li>
            </ul>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4 mt-3">
            <div className="teacher-fil">
              <h6 className="ps-4">Filter By</h6>
              <div className="bg-white py-3 px-3 position-relative">
                <select className="border-0 w-auto form-select">
                  <option>By Venue</option>
                </select>
                <img
                  src="assets/img/filter.png"
                  className="filter-icon rounded"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-12 mt-3">
            <div className="tab-content" id="pills-tabContent">
              <div
                className={
                  type === "new" ? "tab-pane fade show active" : "tab-pane fade"
                }
                id="pills-new"
                role="tabpanel"
                aria-labelledby="pills-new-tab"
              >
                <div className="row">
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-3">
                    <BookingCard type="new" />
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="pills-completed"
                role="tabpanel"
                aria-labelledby="pills-completed-tab"
              >
                <div className="row">
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-3">
                    <BookingCard type="" />
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="pills-canceled"
                role="tabpanel"
                aria-labelledby="pills-canceled-tab"
              >
                <div className="row">
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-3">
                    <BookingCard type="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Bookings;
