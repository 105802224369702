import { configureStore } from "@reduxjs/toolkit";
import ClassSlice from "../features/class/ClassSlice";
import slice from "../features/slice";
import StudentSlice from "../features/student/StudentSlice";
import NotificationSlice from "./../features/notification/NotificationSlice";

export const store = configureStore({
  reducer: {
    class: ClassSlice,
    notification: NotificationSlice,
    slice: slice,
    student: StudentSlice,
  },
});
