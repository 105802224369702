import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { cancelClass } from "../../api/class";
import CancelTimeSlot from "../../component/Modal/CancelTimeSlot";
import { useToggle } from "../../utils";
import { FetchClassDetails, getClassDetails } from "./ClassSlice";

function ClassDetail() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const data = useSelector(getClassDetails);
  const [studentList, setStudentList] = useState([]);
  const [index, setIndex] = useState(0);
  const [show, handleShow] = useToggle(false);
  const [classToCancel, setClassToCancel] = useState(null);

  useEffect(() => {
    dispatch(FetchClassDetails(id)).then((res) => {
      if (res?.payload?.code === 1) {
        setStudentList(res?.payload?.data?.student_list);
      }
    });
  }, [dispatch, id]);

  const handleCancel = (reason) => {
    cancelClass({ ...classToCancel, cancel_reason: reason }).then((res) => {
      if (res?.code === 1) {
        toast.success(res?.message);
        dispatch(FetchClassDetails(id)).then((res) => {
          if (res?.payload?.code === 1) {
            setStudentList(res?.payload?.data?.student_list);
            handleShow();
          }
        });
      } else {
        toast.error(res?.message);
      }
    });
  };
  return (
    <section className="sec-bg py-4 px-3">
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 mb-3 position-relative">
            <h3 className="text-capitalize">
              {data?.class_details?.name} details
            </h3>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-12 mt-3">
            <div className="bg-white p-4 rounded h-100 shadow position-relative">
              <span className="class-date fs-5">
                <img src="assets/img/address.png" className="" alt="" />{" "}
                {
                  data?.class_details?.school_details?.areacode_details
                    ?.areacode
                }
              </span>
              <div
                className="event-box-flex"
                style={{
                  alignItems: "unset",
                }}
              >
                <span>
                  <img
                    src={
                      data?.class_details?.image?.includes("http")
                        ? data?.class_details?.image
                        : "assets/img/headerlogo.png"
                    }
                    className="event-img"
                    alt=""
                  />
                </span>

                <span>
                  <h4>{data?.class_details?.name}</h4>

                  <p className="mb-0 ">
                    {data?.class_details?.discipline_details?.name}
                  </p>

                  <p className="mb-0 ">
                    {data?.class_details?.school_details?.name}
                  </p>
                  <p className="mb-0 ">
                    <img src="assets/img/address.png" className="" alt="" />{" "}
                    {data?.class_details?.school_details?.address}
                  </p>
                  <p className="mb-0 ">
                    Total Seats: {data?.class_details?.total_seats || "NA"}
                  </p>
                  {data?.class_details?.child_price !== "" ? (
                    <p className="fw-bold mb-0 theme-color mr-2 fs-4">
                      <i className="fa fa-gbp" aria-hidden="true"></i>{" "}
                      {data?.class_details?.child_price}
                    </p>
                  ) : data?.class_details?.adult_price !== "" ? (
                    <p className="fw-bold mb-0 theme-color mr-2 fs-4">
                      <i className="fa fa-gbp" aria-hidden="true"></i>{" "}
                      {data?.class_details?.adult_price}
                    </p>
                  ) : data?.class_details?.child_price !== "" &&
                    data?.class_details?.adult_price !== "" ? (
                    <p className="fw-bold mb-0 theme-color mr-2 fs-4">
                      Child: <i className="fa fa-gbp" aria-hidden="true"></i>{" "}
                      {data?.class_details?.child_price} Adult:{" "}
                      <i className="fa fa-gbp" aria-hidden="true"></i>{" "}
                      {data?.class_details?.adult_price}
                    </p>
                  ) : null}
                </span>
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-12 col-lg-12 mt-3">
            <div className="bg-white p-4 rounded h-100 shadow">
              <h3>About Class</h3>
              <p className="mt-2">{data?.class_details?.about}</p>
            </div>
          </div>

          <div className="col-sm-12 mt-2">
            <div
              className="accordion Classes-colles shadow"
              id="accordionExample"
            >
              <div className="bg-white text-capitalize mt-3">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="heading1">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapse1`}
                      aria-expanded="true"
                      aria-controls="collapse1"
                    >
                      <h3>Date and Time</h3>
                    </button>
                  </h2>
                  <div
                    id={`collapse1`}
                    className={"accordion-collapse collapse "}
                    aria-labelledby="heading1"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div className="calendar">
                        <div className="month mb-3">
                          <ul>
                            <li
                              className="prev cursor-pointer"
                              onClick={() => {
                                if (index === 0) {
                                  setIndex(
                                    data?.class_details?.time_slot_list
                                      ?.length - 1
                                  );
                                } else if (
                                  index <=
                                  data?.class_details?.time_slot_list?.length -
                                    1
                                ) {
                                  setIndex(index - 1);
                                } else {
                                  setIndex(0);
                                }
                              }}
                            >
                              &#10094;
                            </li>
                            <li>
                              {
                                data?.class_details?.time_slot_list?.[index]
                                  ?.month
                              }{" "}
                              {
                                data?.class_details?.time_slot_list?.[index]
                                  ?.year
                              }
                            </li>
                            <li
                              className="next cursor-pointer"
                              onClick={() => {
                                if (index === 0) {
                                  setIndex(index + 1);
                                } else if (
                                  index <
                                  data?.class_details?.time_slot_list?.length -
                                    1
                                ) {
                                  setIndex(index + 1);
                                } else if (
                                  index ===
                                  data?.class_details?.time_slot_list?.length -
                                    1
                                ) {
                                  setIndex(0);
                                }
                              }}
                            >
                              &#10095;
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="row student-container">
                        {data?.class_details?.time_slot_list?.[index]?.time_slot
                          ?.length > 0 ? (
                          data?.class_details?.time_slot_list?.[
                            index
                          ]?.time_slot?.map((itemm, ind) => (
                            <div
                              className="col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-3"
                              key={ind}
                            >
                              <label
                                htmlFor={`classes1${ind}`}
                                className="student__details classDetails"
                              >
                                <h5>
                                  {moment(itemm?.date).format("dddd")}{" "}
                                  {moment(itemm?.date).format("DD MMM")}
                                </h5>
                                <p className="mb-0">
                                  <label className="theme-color">
                                    {itemm?.time_slot_for === "both"
                                      ? "Age Groups: "
                                      : "Age Group: "}
                                  </label>{" "}
                                  {itemm?.time_slot_for === "both"
                                    ? `Adult | Child`
                                    : itemm?.time_slot_for}
                                </p>
                                <p className="mb-0">
                                  <label className="theme-color">Time:</label>{" "}
                                  {itemm?.start_time
                                    ? `${moment(
                                        itemm?.start_time,
                                        "hh:mm:ss"
                                      ).format("hh:mm a")}-${moment(
                                        itemm?.end_time,
                                        "hh:mm:ss"
                                      ).format("hh:mm a")}`
                                    : "NA"}
                                </p>
                                {itemm?.isBooked && (
                                  <p className="text-danger">Already Booked</p>
                                )}
                                <p
                                  className={
                                    itemm?.isCancelled
                                      ? "text-danger"
                                      : "text-success"
                                  }
                                >
                                  Status:{" "}
                                  {itemm?.isCancelled ? "Cancelled" : "Active"}
                                </p>
                                <button
                                  className="btn btn-submit p-0 float-end w-50 mt-2 fw-normal mb-0 fs-5"
                                  onClick={() => {
                                    if (!itemm?.isCancelled) {
                                      setClassToCancel({
                                        time_slot_id: itemm?.time_slot_id,
                                        classId: data?.class_details?.id,
                                        date: itemm?.date,
                                      });
                                      handleShow();
                                    }
                                  }}
                                >
                                  {itemm?.isCancelled
                                    ? "Cancelled"
                                    : "Cancel Class"}
                                </button>
                              </label>
                            </div>
                          ))
                        ) : (
                          <span>No classes found.</span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {data?.booking_count > 0 && (
            <div className="col-sm-12 col-md-12 col-lg-12 mt-3">
              <div className="bg-white p-4 rounded h-100 shadow">
                <h3>People are joining</h3>
                <p>
                  {studentList?.student_list?.map((item) => {
                    return (
                      <img
                        src={
                          item?.image?.includes("http")
                            ? item?.image
                            : "assets/img/headerlogo.png"
                        }
                        className="ppl-img"
                        alt=""
                      />
                    );
                  })}{" "}
                  {data?.booking_count > 1
                    ? `${data?.booking_count} Students`
                    : `${data?.booking_count} Student`}{" "}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>

      {show && classToCancel && (
        <CancelTimeSlot
          page="Class"
          show={show}
          handleShow={handleShow}
          handleCancel={handleCancel}
          setClassToCancel={setClassToCancel}
        />
      )}
    </section>
  );
}

export default ClassDetail;
