import React, { useState } from "react";
import Otp from "../../component/Modal/Otp";
import { Formik } from "formik";
import * as Yup from "yup";

function QualificationInfo() {
  const [show, setShow] = useState(false);
  const handleShow = () => {
    setShow(!show);
  };
  return (
    <section className="bg-image">
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6">
            <div className="logo-bg position-relative">
              <p>
                <img
                  src="assets/img/headerlogo.png"
                  className="logo-icon"
                  alt="logo"
                />
              </p>
              <p>
                <img
                  src="assets/img/banner_logo.png"
                  className="logo-img"
                  alt="logo"
                />
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6">
            <div className="form-style">
              <Formik
                initialValues={{
                  highestQualification: "",
                  expertise: "",
                  award: "",
                  image: [],
                }}
                validationSchema={Yup.object({
                  highestQualification: Yup.string().required("Required"),
                  expertise: Yup.string().required("Required"),
                  award: Yup.string().required("Required"),
                  // image: Yup.array().min(1, "Atleast one image is required."),
                })}
                onSubmit={({ values }) => {
                  handleShow();
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFormikState,
                  setFieldValue,
                }) => (
                  <form className="row" onSubmit={handleSubmit}>
                    <div className="form-group col-sm-12 col-md-12 text-center mb-4">
                      <h2 className="mdl-ttl">qualification info</h2>
                    </div>
                    <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                      <div className="input-container">
                        <select
                          className="form-control ps-4 form-select"
                          name="highestQualification"
                          onChange={(e) => {
                            setFieldValue(
                              "highestQualification",
                              e.target.value
                            );
                          }}
                        >
                          <option value="">Highest Qualification</option>

                          <option value="BA">BA</option>
                        </select>
                        <img
                          src="assets/img/edu-black.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                      <div className="input-container">
                        <input
                          className="form-control ps-4"
                          placeholder="Expertise in Subject"
                          name="expertise"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.expertise}
                        />
                        <img
                          src="assets/img/expertise.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                      {errors.expertise && touched.expertise && (
                        <div className="text-red">{errors.expertise}</div>
                      )}
                    </div>
                    <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                      <div className="input-container">
                        <input
                          type="text"
                          className="form-control ps-4"
                          placeholder="Award Name"
                          name="award"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.award}
                        />
                        <img
                          src="assets/img/award.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                      {errors.award && touched.award && (
                        <div className="text-red">{errors.award}</div>
                      )}
                    </div>
                    <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                      <label>
                        <img
                          src="assets/img/document.png"
                          className=""
                          alt=""
                        />{" "}
                        Upload Document
                      </label>
                    </div>
                    {errors.image && touched.image && (
                      <div className="text-red">{errors.image}</div>
                    )}
                    <div className="form-group col-sm-6 col-md-6 col-lg-4 mb-4">
                      <div className="upload-btn-wrapper">
                        <button className="btn">
                          <img
                            src="assets/img/upload-doc.png"
                            alt=""
                            className="rounded-0"
                          />
                        </button>
                        <input type="file" name="myfile" />
                      </div>
                    </div>
                    <div className="form-group col-sm-6 col-md-6 col-lg-4 mb-4">
                      <div className="upload-btn-wrapper">
                        <button className="btn">
                          <img
                            src="assets/img/upload-doc.png"
                            alt=""
                            className="rounded-0"
                          />
                        </button>
                        <input type="file" name="myfile" />
                      </div>
                    </div>
                    <div className="form-group col-sm-6 col-md-6 col-lg-4 mb-4">
                      <div className="upload-btn-wrapper">
                        <button className="btn">
                          <img
                            src="assets/img/upload-doc.png"
                            alt=""
                            className="rounded-0"
                          />
                        </button>
                        <input type="file" name="myfile" />
                      </div>
                    </div>
                    <div className="form-group col-sm-12 col-md-12 text-center">
                      <button className="btn btn-submit">Done</button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      <Otp show={show} handleShow={handleShow} />
    </section>
  );
}

export default QualificationInfo;
