import React from "react";
import { Modal } from "react-bootstrap";

function Otp({ show, handleShow }) {
  const Comp = () => {
    return (
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content other-city b-r-20">
          <div className="modal-header text-center">
            <h2 className="mdl-ttl">OTP Verification</h2>
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                handleShow();
              }}
            ></button>
          </div>
          <div className="modal-body">
            <form className="row form-style profile-form">
              <div className="form-group col-sm-12 col-md-12 text-center mb-2">
                <div className="text-center mb-5">
                  <img src="assets/img/otp.png" className="img-fluid" alt="" />
                </div>
                <p className="mdl-sbttl">
                  Please enter 6 digit code which has been send on your
                  <br /> registered email address.
                </p>
              </div>
              <div className="form-group col-sm-12 col-md-12 mb-4">
                <div className="passcode-wrapper">
                  <input
                    id="codeBox1"
                    type="number"
                    maxlength="1"
                    placeholder="0"
                  />
                  <input
                    id="codeBox2"
                    type="number"
                    maxlength="1"
                    placeholder="0"
                  />
                  <input
                    id="codeBox3"
                    type="number"
                    maxlength="1"
                    placeholder="0"
                  />
                  <input
                    id="codeBox4"
                    type="number"
                    maxlength="1"
                    placeholder="0"
                  />
                  <input
                    id="codeBox5"
                    type="number"
                    maxlength="1"
                    placeholder="0"
                  />
                  <input
                    id="codeBox6"
                    type="number"
                    maxlength="1"
                    placeholder="0"
                  />
                </div>
              </div>
              <div className="form-group col-sm-12 col-md-12 text-center">
                <p className="otp-timing">00:35</p>
              </div>
              <div className="form-group col-sm-12 col-md-12 text-center">
                <button className="btn btn-submit">Verify</button>
              </div>
              <div className="form-group col-sm-12 col-md-12 text-center mb-4">
                <a href="#" className="forgot-pass">
                  Resend Code
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  };
  return <Modal show={show} onHide={handleShow} dialogAs={Comp} />;
}

export default Otp;
