import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  FetchAppointments,
  FetchClassDetails,
  FetchScheduledCall,
  getAppointments,
  getClassDetails,
} from "../class/ClassSlice";

import { useSelector } from "react-redux";
import CalendarComp from "../../component/Calander";
import moment from "moment";
import WeekStrip from "react-weekstrip-daypicker";
import { FetchTeacherDetails, getTeacherInfo } from "../slice";
import { getData } from "../../api";

function Appointments() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector(getTeacherInfo);

  const myEventsList = useSelector(getAppointments);
  const classDetail = useSelector(getClassDetails);
  const [appointmentDate, setAppointmentDate] = useState({
    month: moment().format("M"),
    year: moment().format("YYYY"),
    date: moment(),
  });
  const [studentList, setStudentList] = useState([]);
  const scheduledClass = useSelector((state) => state.class.scheduledClass);

  const [classId, setClassId] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedDateClasses, setSelectedDateClasses] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");

  const getTeacherDetails = useCallback(() => {
    dispatch(FetchTeacherDetails());
  }, [dispatch]);

  useEffect(() => {
    getTeacherDetails();
  }, [getTeacherDetails]);

  useEffect(() => {
    dispatch(FetchAppointments({}));
  }, [dispatch, appointmentDate]);

  useEffect(() => {
    if (classId.length > 0) {
      setLoading(true);
      getData("teacher/assignClassList", {
        page: 1,
        limit: 10000,
        date: "",
        class_ids: JSON.stringify(classId),
      }).then((res) => {
        if (res?.code === 1) {
          setLoading(false);
          console.log(res, "Res");
          // setStudentList(res?.payload?.data?.student_list);
          setSelectedDateClasses(res?.data?.class_list);
        }
      });
    }
  }, [dispatch, classId]);

  useEffect(() => {
    dispatch(
      FetchScheduledCall({
        page: 1,
        limit: 10000,
        date: moment(appointmentDate?.date).format("YYYY-MM-DD"),
      })
    );
  }, [dispatch, appointmentDate.date]);

  return (
    <section>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
            <div className="sec-bg py-4 px-3 record-listing h-100">
              <h3 className="sec-title fs-4 mb-3">appointments</h3>

              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12 mb-3 px-sm-5">
                  {
                    <CalendarComp
                      data={
                        myEventsList?.length > 0
                          ? myEventsList.map((it) => {
                              if (it?.event_id) {
                                return {
                                  ...it,
                                  end: moment
                                    .utc(it?.start)
                                    .startOf("day")
                                    .format(),
                                  start: moment
                                    .utc(it?.start)
                                    .startOf("day")
                                    .format(),
                                  start_time: moment
                                    .utc(it?.start)
                                    .format("HH:mm:ss"),
                                };
                              }
                              return it;
                            })
                          : []
                      }
                      setAppointmentDate={setAppointmentDate}
                      appointmentDate={appointmentDate}
                      setClassId={setClassId}
                      setSelectedDate={setSelectedDate}
                    />
                  }
                </div>

                {selectedDate && selectedDateClasses?.length > 0 && (
                  <div className="col-sm-12">
                    <h4>Classes</h4>
                    <div class="table-responsive text-center">
                      <table class="table storetable">
                        <thead class="text-center">
                          <tr>
                            <th scope="col">Class Name</th>
                            <th scope="col">Date</th>
                            <th scope="col">Time</th>
                            <th scope="col">Venue</th>
                            <th scope="col">Actions</th>
                          </tr>
                          <tr class="border-0 bg-none invisible">
                            <td class="border-0 bg-none"></td>
                          </tr>
                        </thead>
                        <tbody>
                          {selectedDateClasses?.map((item, i) => {
                            return (
                              <tr key={i}>
                                <td>{item?.class_details?.name}</td>
                                <td>{selectedDate}</td>
                                <td>
                                  {item?.class_details?.time_slot_list_with_id
                                    ?.map((it) => {
                                      return (
                                        moment(
                                          it?.start_time,
                                          "hh:mm:ss"
                                        ).format("hh:mm a") +
                                        "-" +
                                        moment(it?.end_time, "hh:mm:ss").format(
                                          "hh:mm a"
                                        )
                                      );
                                    })
                                    ?.join(", ")}
                                </td>
                                <td>
                                  {item?.class_details?.school_details?.name}
                                </td>
                                <td>
                                  <div className="d-flex justify-content-center action align-items-center">
                                    <button
                                      className="btn"
                                      title="View Student"
                                    >
                                      <span
                                        onClick={() => {
                                          const start_time =
                                            item?.class_details
                                              ?.time_slot_list_with_id?.[0]
                                              ?.start_time;
                                          const end_time =
                                            item?.class_details
                                              ?.time_slot_list_with_id?.[0]
                                              ?.end_time;
                                          const dateFor =
                                            moment(selectedDate).format(
                                              "YYYY-MM-DD"
                                            );
                                          const time_slot_id =
                                            item?.class_details
                                              ?.time_slot_list_with_id?.[0]?.id;
                                          navigate(
                                            `/register/class/${item?.class_details?.id}/${dateFor}/${start_time}/${end_time}/${time_slot_id}`
                                          );
                                        }}
                                      >
                                        <img src="assets/img/view.png" alt="" />
                                      </span>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}

                <div className="col-sm-12 col-md-12 col-lg-12 mt-3">
                  <label className="fw-bold largeLabel">Assigned Classes</label>
                </div>

                <div className="col-sm-12 col-md-12 col-lg-6 mt-3">
                  {scheduledClass?.length > 0 ? (
                    scheduledClass?.map((item, i) => {
                      return (
                        <div
                          key={i}
                          className="bg-white p-2 mb-4 rounded mb-2 shadow position-relative cursor-pointer"
                          onClick={() => {
                            navigate(`/class/${item?.id}`);
                          }}
                        >
                          <span className="class-date">{item?.date}</span>
                          <div className="event-box-flex">
                            <span>
                              <img
                                src={
                                  item?.class_details?.image?.includes("http")
                                    ? item?.class_details?.image
                                    : "assets/img/headerlogo.png"
                                }
                                className="event-img"
                                alt=""
                              />
                            </span>
                            <span>
                              <h5>{item?.class_details?.name}</h5>
                              <p className="mb-0">
                                {" "}
                                {item?.class_details?.time_slot_list?.[0]
                                  ?.time_slot_for === "both"
                                  ? `Adult | Child`
                                  : item?.class_details?.time_slot_list?.[0]
                                      ?.time_slot_for}
                              </p>
                              <p className="mb-0">
                                {item?.class_details?.school_details?.name}
                              </p>
                              <p className="mb-0">
                                <img
                                  src="assets/img/address.png"
                                  className=""
                                  alt=""
                                />{" "}
                                {item?.class_details?.school_details?.address}
                              </p>
                            </span>
                          </div>
                          <div className="joining-flex">
                            {item?.students.length > 0 ? (
                              <span>
                                {item?.students.map((item) => {
                                  return (
                                    <img
                                      src={
                                        item?.image?.includes("http")
                                          ? item?.image
                                          : "assets/img/headerlogo.png"
                                      }
                                      key={i}
                                      className="ppl-img"
                                      alt=""
                                    />
                                  );
                                })}
                                <small>
                                  {item?.booking_count}{" "}
                                  {item?.students.length > 1
                                    ? "people"
                                    : "person"}{" "}
                                  {item?.students.length > 1 ? "have" : "has"}{" "}
                                  joined
                                </small>
                              </span>
                            ) : (
                              <span></span>
                            )}
                            {/* <Link
                              to={`/class/${item?.id}`}
                              className="left-seats cursor-pointer"
                            >
                              <span>View</span>
                            </Link> */}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div>No class scheduled</div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-12 col-lg-12 mt-3 mb-3">
            <div className="sec-bg p-3 rounded expandible">
              <h4>Teacher's Details</h4>

              <div className="row bg-white py-2">
                <div className="col-sm-12 col-md-6 col-lg-2 mb-2">
                  <p className="detail-account text-capitalize position-relative">
                    <img
                      src="assets/img/First-Name.png"
                      className="icon-title"
                      alt=""
                    />
                    <span>Full Name</span>
                    <span>
                      <b>{data?.full_name || "NA"}</b>
                    </span>
                  </p>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-5 mb-2">
                  <p className="detail-account position-relative">
                    <img
                      src="assets/img/email.png"
                      className="icon-title"
                      alt=""
                    />
                    <span className="text-capitalize">email ID</span>
                    <span>
                      <b>{data?.email || "NA"}</b>
                    </span>
                  </p>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-3 mb-2">
                  <p className="detail-account position-relative">
                    <img
                      src="assets/img/First-Name.png"
                      className="icon-title"
                      alt=""
                    />
                    <span className="text-capitalize">Mobile Number</span>
                    <span>
                      <b>
                        {data?.mobile
                          ? `${data?.countryCode}${data?.mobile}`
                          : "NA"}
                      </b>
                    </span>
                  </p>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12">
                  <hr />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-2 mb-2">
                  <p className="detail-account text-capitalize position-relative">
                    <img
                      src="assets/img/date-of-birth.png"
                      className="icon-title"
                      alt=""
                    />
                    <span>date of birth</span>
                    <span>
                      <b>
                        {data?.address?.dob
                          ? moment(data?.address?.dob).format("DD MMM YYYY")
                          : data?.address?.dob_year
                          ? moment(
                              `${data?.address?.dob_year}-${data?.address?.dob_month}-${data?.address?.dob_day}`
                            ).format("DD MMM YYYY")
                          : "NA"}
                      </b>
                    </span>
                  </p>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-5 mb-2">
                  <p className="detail-account text-capitalize position-relative">
                    <img
                      src="assets/img/address.png"
                      className="icon-title"
                      alt=""
                    />
                    <span className="">full address</span>
                    <span>
                      <b>
                        {data?.address
                          ? `${data?.address?.property_number}${
                              data?.property_name
                                ? `, ${data?.property_name}, `
                                : ", "
                            }${data?.address?.street_number}, ${
                              data?.address?.town
                            }, ${data?.address?.country}-${
                              data?.address?.postal_code
                            }`
                          : "NA"}
                      </b>
                    </span>
                  </p>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12">
                  <hr />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-2 mb-2">
                  <p className="detail-account position-relative">
                    <img
                      src="assets/img/edu-black.png"
                      className="icon-title"
                      alt=""
                    />
                    <span className="text-capitalize">Qualifications</span>
                    <span>
                      <b>
                        {data?.qualification_list?.length > 0
                          ? data?.qualification_list
                              ?.map((item) => item?.qualification)
                              .join(", ")
                          : "NA"}
                      </b>
                    </span>
                  </p>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-5 mb-2">
                  <p className="detail-account position-relative">
                    <img
                      src="assets/img/edu-black.png"
                      className="icon-title"
                      alt=""
                    />
                    <span className="text-capitalize">
                      Expertise in Subject
                    </span>
                    <span>
                      <b>
                        {data?.subject_list?.length > 0
                          ? data?.subject_list
                              ?.map((item) => item?.subject)
                              .join(", ")
                          : "NA"}
                      </b>
                    </span>
                  </p>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-5 mb-2">
                  <p className="detail-account position-relative">
                    <img
                      src="assets/img/edu-black.png"
                      className="icon-title"
                      alt=""
                    />
                    <span className="text-capitalize">Awards</span>
                    <span>
                      <b>
                        {data?.award_list?.length > 0
                          ? data?.award_list
                              ?.map((item) => item?.award)
                              .join(", ")
                          : "NA"}
                      </b>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Appointments;
