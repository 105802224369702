import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getData } from "./../../api/index";
import {
  getAreacodeList,
  getDisciplineList,
  getStudentList,
} from "../../api/student";

const initialState = {};

export const StudentSlice = createSlice({
  name: "studentSlice",
  initialState,
  reducers: {
    list: (state, action) => {
      state.list = action.payload;
    },
    count: (state, action) => {
      state.totalStudent = action.payload;
    },
    areacodeList: (state, action) => {
      state.areacodes = action.payload;
    },
    disciplineList: (state, action) => {
      state.disciplines = action.payload;
    },
    schoolList: (state, action) => {
      state.schools = action.payload;
    },
  },
});

export const { list, count, areacodeList, disciplineList, schoolList } =
  StudentSlice.actions;

export const FetchStudentList = createAsyncThunk(
  "FetchStudentList",
  async (obj, { dispatch }) => {
    try {
      //   dispatch(isLoading(true));
      const response = await getStudentList(obj);
      if (response?.code === 1) {
        dispatch(list(response?.data?.student_list));
        dispatch(count(response?.data?.total_active_student));
      }

      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const FetchAreacode = createAsyncThunk(
  "FetchAreacode",
  async (obj, { dispatch }) => {
    try {
      //   dispatch(isLoading(true));
      const response = await getAreacodeList(obj);
      if (response?.code === 1) {
        console.log(response, "Areacode");
        dispatch(areacodeList(response?.data?.areacode_list));
      }

      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const FetchDiscipline = createAsyncThunk(
  "FetchDiscipline",
  async (obj, { dispatch }) => {
    try {
      //   dispatch(isLoading(true));
      const response = await getDisciplineList(obj);
      if (response?.code === 1) {
        console.log(response, "Discipline");
        dispatch(disciplineList(response?.data?.discipline_list));
      }

      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const SchoolListReducer = createAsyncThunk(
  "SchoolListReducer",
  async (obj, { dispatch }) => {
    try {
      const response = await getData("admin/schoolList", obj);

      if (response?.code === 1) {
        dispatch(schoolList(response?.data?.school_list));
      }

      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export default StudentSlice.reducer;
