import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import AddStudent from "../../component/Modal/AddStudent";
import {
  changeRegisterFor,
  FetchAllOtherStudents,
  FetchAllOtherStudentsForEvent,
  FetchRegisterDetail,
  FetchRegisterList,
  FetchStudentForClass,
  getAllStudentOfAClass,
} from "./ClassSlice";
import Loader from "../../component/Loader";
import { CSVLink } from "react-csv";
import moment from "moment";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router";
import {
  addStudentInClass,
  addStudentInEvent,
  saveClassAttendance,
  saveEventAttendance,
} from "../../api/class";
import { useToggle } from "../../utils";
import PrintComponent from "../../component/Modal/PrintComponent";
import PrintEventComponent from "../../component/Modal/PrintEventComponent";
import Select from "react-select";
import { FetchTeacherDetails, getTeacherInfo } from "../slice";
import { postFormData } from "../../api";
import ChangeStudentClass from "../../component/Modal/ChangeStudentClass";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 650 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 650, min: 0 },
    items: 1,
  },
};

const style = {
  control: (styles) => ({
    ...styles,
    // none of react-select's styles are passed to <Control />
    borderWidth: 0,
    padding: "2px",
    margin: 0,
    borderColor: "transparent",

    boxShadow: "unset",

    "&:hover": {
      borderColor: "transparent",
      outline: "0",
      boxShadow: "unset",
    },
  }),
  container: (styles) => ({
    ...styles,
    // none of react-select's styles are passed to <Control />
    backgroundImage: "none",
    margin: 0,
    paddingRight: "2px",
    borderRadius: "10px",
  }),
};

function Register() {
  const { id, start_time, end_time, dateFor } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const teacherDetail = useSelector(getTeacherInfo);
  const studentList = useSelector(getAllStudentOfAClass);
  const registerList = useSelector((state) => state.class.registerList);
  const register_for = useSelector((state) => state.class.register_for);
  const data = useSelector((state) => state.class.classDetail);
  const eventList = useSelector((state) => state.class.eventList);
  const studentForClass = useSelector(
    (state) => state.class.otherStudentsListForClass
  );
  // const studentForEvent = useSelector(
  //   (state) => state.class.otherStudentsListForEvents
  // );
  const [selectedId, setSelectedId] = useState("");
  // const [levelId, setLevelId] = useState("");
  const [show, setShow] = useState(false);
  const [modalName, setModalName] = useState("");
  const [loading, setLoading] = useState(false);
  const [classLoading, setClassLoading] = useState(false);
  // const [csv, setCsv] = useState("null");
  const [class_name, setClass_name] = useState("");
  const [tab, setTab] = useState("all");
  const [date, setDate] = useState({ date: "" });
  const [time, setTime] = useState({
    start_time: "",
    end_time: "",
  });
  const [availableTimeSlots, setAvailableTimeSlots] = useState(null);
  const [availableDates, setAvailableDates] = useState(null);
  const [showAddStudent, toggle] = useToggle(false);
  const [changeClassForStudentData, setChangeClassForStudentData] =
    useState(null);
  const [showClass, handleShowClass] = useToggle(false);

  const handleShow = () => {
    setShow(!show);
  };

  const getTeacherDetails = useCallback(() => {
    dispatch(FetchTeacherDetails());
  }, [dispatch]);

  useEffect(() => {
    getTeacherDetails();
  }, [getTeacherDetails]);

  useEffect(() => {
    if (id && start_time) {
      console.log(start_time, end_time, id, "id inside register");
      setSelectedId(id);
      setTime({
        start_time: start_time,
        end_time: end_time ? end_time : "",
      });
      setDate({ date: dateFor ? dateFor : "" });
    }
  }, [start_time, end_time, id, dateFor]);

  useEffect(() => {
    if (teacherDetail || (id && start_time && end_time && dateFor)) {
      dispatch(
        FetchRegisterList({
          page: 1,
          limit: 10,
          teacherId: teacherDetail?.id,
          register_for,
        })
      ).then((res) => {
        console.log(res, "Register Listing");
        const data = res?.payload?.data?.register_list;
        if (!id) {
          setSelectedId(data?.[0]?.id);
        }
        // setLevelId(data?.[0]?.level_id || "");
      });
    }
  }, [
    dispatch,
    register_for,
    teacherDetail,
    id,
    start_time,
    end_time,
    dateFor,
  ]);

  const getDetails = useCallback(() => {
    if (selectedId) {
      setLoading(true);
      dispatch(
        FetchRegisterDetail({
          classId: register_for === "class" ? selectedId : "",
          eventId: "",
          register_for: register_for,
          date: date.date,
          time: "",
          start_time: time?.start_time,
          end_time: time?.end_time,
          levelId: "",
        })
      ).then((res) => {
        setLoading(false);
        if (res?.payload?.code === 1) {
          var times = res?.payload?.data?.register_details?.time_slot
            ?.filter((it) => it?.isCancelled === false)
            ?.map((value) => {
              return {
                start_time: value?.start_time,
                end_time: value?.end_time,
                time_slot_id: value?.time_slot_id,
              };
            })
            ?.filter(
              (value, index, _arr) =>
                _arr.findIndex(
                  (it) =>
                    it?.start_time === value.start_time &&
                    it?.end_time === value.end_time &&
                    it?.time_slot_id === value?.time_slot_id
                ) === index
            )
            .sort(function (a, b) {
              return (
                new Date(a.start_time) - new Date(b.start_time) &&
                new Date(a.end_time) - new Date(b.end_time)
              );
            });

          var days = res?.payload?.data?.register_details?.time_slot
            ?.filter((it) => it?.isCancelled === false)
            ?.map((value) => {
              return { date: value.date };
            })
            ?.filter(
              (value, index, _arr) =>
                _arr.findIndex(
                  (it) =>
                    it?.date?.toString() === value?.date?.toString() &&
                    it?.time_slot_id === value?.time_slot_id
                ) === index
            )
            ?.sort(function (a, b) {
              return new Date(a.date) - new Date(b.date);
            });
          console.log(days);
          setAvailableTimeSlots(times);
          setAvailableDates(days);

          if (date.date === "" && time.start_time === "") {
            setDate(
              date?.date !== ""
                ? date
                : {
                    date: days?.find(
                      (it) => moment(it?.date).diff(moment()) > 0
                    )?.date,
                  }
            );
            setTime((prev) => {
              if (prev.start_time && prev.end_time) {
                return time;
              } else {
                return {
                  start_time: times?.[0]?.start_time,
                  end_time: times?.[0]?.end_time,
                };
              }
            });
          }
        }
      });
    }
  }, [dispatch, date, time, register_for, selectedId]);

  useEffect(() => {
    getDetails();
  }, [getDetails]);

  const getStudentListing = useCallback(() => {
    dispatch(FetchAllOtherStudents(selectedId));
  }, [dispatch, selectedId]);

  useEffect(() => {
    getStudentListing();
  }, [getStudentListing]);

  const addStudentToRegister = (student, _time, _date) => {
    const payload = {
      userId: student?.userId,
      total_amount:
        student?.student_type === "child"
          ? data?.child_price
          : student?.student_type === "adult"
          ? data?.adult_price
          : 0,
      schoolId: data?.schoolId,
      classId: selectedId,
      teacherId: data?.assigned_teacher?.teacher_details?.id,
      child_price: data?.child_price,
      adult_price: data?.adult_price,
      date: _date.date,
      start_time: _time.start_time,
      end_time: _time.end_time,
      time_slot_for: student?.student_type,
      time_slot_id: _time?.time_slot_id,
      studentId: student?.id,
      student_type: student?.student_type,
      trialAccountStatus: student?.trialAccountStatus,
    };
    addStudentInClass(payload)
      .then((res) => {
        if (res?.code === 1) {
          toast.success(res?.message);
          getDetails();
        } else {
          toast.error(res?.message);
        }
        return res;
      })
      .then((response) => {
        if (response?.code === 1) {
          toggle();
        }
      });
  };

  useEffect(() => {
    if (selectedId) {
      setLoading(true);
      dispatch(
        FetchStudentForClass({
          id: selectedId,
          params: { page: 1, limit: 10000000 },
        })
      ).then(() => {
        setLoading(false);
      });
    }
  }, [dispatch, selectedId]);

  useEffect(() => {
    if (registerList && selectedId) {
      let name = registerList?.find((it) => {
        return it.id === selectedId;
      })?.class_details?.name;
      setClass_name(name);
    }
  }, [selectedId, registerList]);

  // useEffect(() => {
  //   if (studentList) {
  //     let arr = studentList?.map((it) => {
  //       return {
  //         first_name: it?.first_name,
  //         last_name: it?.last_name,
  //         attendance: it?.attendance ? it?.attendance : "Not Marked.",
  //       };
  //     });
  //     setCsv(arr);
  //   }
  // }, [studentList]);

  const StudentAttendance = ({ dataa, status }) => {
    const payload = {
      studentId: dataa?.id,
      classId: selectedId,
      date: date.date,
      start_time: time?.start_time,
      end_time: time?.end_time,
      status: status,
    };
    if (date && time) {
      saveClassAttendance(payload).then((res) => {
        if (res?.code === 1) {
          toast.success(res?.message);
          getDetails();
        } else {
          toast.error(res?.message);
        }
      });
    } else {
      toast.error("Kindly select a date and time.");
    }
  };

  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    return (
      <div className="carousel-button-group top-0" style={{ right: 20 }}>
        <button
          onClick={() => previous()}
          className={"slick-prev slick-arrow"}
          aria-label="Previous"
          type="button"
        >
          Previous
        </button>
        <button
          onClick={() => next()}
          className="slick-next slick-arrow"
          aria-label="Next"
          type="button"
        >
          Next
        </button>
      </div>
    );
  };

  const handleChangeClass = (data) => {
    postFormData("class/changeStudentClass", data).then((res) => {
      if (res?.code === 1) {
        toast.success(res?.message);
        getDetails();
        handleShow();
        setChangeClassForStudentData(null);
      } else {
        toast.error(res?.message);
      }
    });
  };

  return (
    <section className="sec-bg py-4 px-3">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
            <div className="view-btns justify-content-end">
              <PrintComponent data={data} date={date.date} time={time} />
            </div>
          </div>
        </div>
        <div className="row ">
          <div className="col-sm-12 col-md-12 col-lg-12 mt-3">
            <h5>{register_for?.toUpperCase()}</h5>
          </div>
          {register_for === "class" ? (
            <>
              {classLoading ? (
                <Loader />
              ) : (
                <div className="col-sm-12 col-md-12 col-lg-12 mt-3 position-relative">
                  {registerList?.length > 0 ? (
                    <Carousel
                      containerClass="student-container class-slider"
                      responsive={responsive}
                      removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                      infinite={false}
                      showDots={false}
                      renderDotsOutside={true}
                      itemClass="p-1"
                      renderButtonGroupOutside={true}
                      customButtonGroup={<ButtonGroup />}
                    >
                      {registerList?.length > 0
                        ? registerList?.map((item, i) => {
                            return (
                              <div className="h-100" key={i}>
                                <input
                                  type="radio"
                                  name="classs"
                                  id={`${item?.id}`}
                                  checked={
                                    // true
                                    selectedId?.toString() ===
                                    item?.id?.toString()
                                  }
                                  onChange={() => {
                                    if (selectedId !== item?.id) {
                                      setSelectedId(item?.id);
                                      setDate({ date: "" });
                                      setTime({
                                        start_time: "",
                                        end_time: "",
                                      });
                                    }
                                  }}
                                />
                                <label
                                  htmlFor={`${item?.id}`}
                                  className="class__details bg-white p-2 rounded h-100 position-relative w-100"
                                >
                                  <div className="">
                                    <span className="class-date">
                                      {/* {moment(
                                    item?.class_details?.time_slot_list?.[0]
                                      ?.start_date
                                  ).format("DD MMM")} */}
                                    </span>
                                    <div className="event-box-flex align-items-normal p-0">
                                      <span>
                                        <img
                                          src={
                                            item?.image?.includes("http")
                                              ? item?.image
                                              : "assets/img/headerlogo.png"
                                          }
                                          className="event-img"
                                          alt=""
                                        />
                                      </span>
                                      <span>
                                        <h6>{item?.name}</h6>
                                        <p className="mb-0 fs-7">
                                          {item?.class_type || "NA"}
                                        </p>
                                        <p className="mb-0 theme-color1 fs-7">
                                          {item?.school_details?.name}
                                        </p>
                                        <p
                                          className="mb-0 fs-7"
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "flex-start",
                                            alignItems: "center",
                                          }}
                                        >
                                          <span>
                                            {item?.school_details?.address}
                                          </span>
                                        </p>
                                      </span>
                                    </div>
                                  </div>
                                </label>
                              </div>
                            );
                          })
                        : null}
                    </Carousel>
                  ) : (
                    <div>No classes found.</div>
                  )}
                </div>
              )}
            </>
          ) : null}

          <div className="col-sm-12 col-md-6 col-lg-9 mt-3"></div>
          <div className="col-sm-12 col-md-6 col-lg-3 mt-3">
            <span
              onClick={toggle}
              class="btn btn-view mb-3 fs-6 p-2  btn-accept"
            >
              Add a New Student in the {register_for}
            </span>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3 registerTeacher">
            <div class="row mt-3">
              <div class="col-sm-12 d-flex justify-content-between">
                <ul
                  class="nav nav-pills group-nav mt-0 mb-3"
                  id="pills-tab"
                  role="tablist"
                >
                  <li class="nav-item" role="presentation">
                    <button
                      class={tab === "all" ? "nav-link active" : "nav-link"}
                      onClick={() => {
                        setTab("all");
                      }}
                      id="pills-all-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-all"
                      type="button"
                      role="tab"
                      aria-controls="pills-all-tab"
                      aria-selected="true"
                    >
                      All
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class={tab === "Present" ? "nav-link active" : "nav-link"}
                      onClick={() => {
                        setTab("Present");
                      }}
                      id="pills-present-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-present"
                      type="button"
                      role="tab"
                      aria-controls="pills-present"
                      aria-selected="true"
                    >
                      present
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class={tab === "Absent" ? "nav-link active" : "nav-link"}
                      onClick={() => {
                        setTab("Absent");
                      }}
                      id="pills-absent-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-absent"
                      type="button"
                      role="tab"
                      aria-controls="pills-absent"
                      aria-selected="false"
                    >
                      absent
                    </button>
                  </li>
                </ul>

                {register_for === "class" && (
                  <div className="d-flex gap-3">
                    <div className="form-group selectAreaCode">
                      <div className="input-container">
                        <Select
                          options={availableTimeSlots || undefined}
                          getOptionLabel={(option) => {
                            return `${moment(
                              option?.start_time,
                              "hh:mm:ss"
                            ).format("hh:mm a")}-${moment(
                              option?.end_time,
                              "hh:mm:ss"
                            ).format("hh:mm a")}`;
                          }}
                          styles={style}
                          getOptionValue={(option) => {
                            return {
                              start_time: option?.start_time,
                              end_time: option?.end_time,
                              time_slot_id: option?.time_slot_id,
                            };
                          }}
                          className="pl-5 form-select timeSelectWidth"
                          value={time || undefined}
                          onChange={(val) => {
                            setTime(val);
                          }}
                        />
                      </div>
                    </div>
                    <div className="form-group selectAreaCode">
                      <div className="input-container">
                        <Select
                          options={availableDates || undefined}
                          getOptionLabel={(option) =>
                            `${moment(option?.date).format("DD MMM YYYY")}`
                          }
                          styles={style}
                          getOptionValue={(option) => option.date}
                          className="pl-5 form-select timeSelectWidth"
                          value={date || undefined}
                          onChange={(val) => {
                            setDate(val);
                          }}
                        />
                        <img
                          src="assets/images/event-name.png"
                          className="input-img inputImage"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div class="col-sm-12">
                <div class="tab-content" id="pills-tab">
                  <div
                    class={
                      tab === "all"
                        ? "tab-pane fade show active"
                        : "tab-pane fade"
                    }
                    id="pills-all"
                    role="tabpanel"
                    aria-labelledby="pills-all-tab"
                  >
                    <div class="table-responsive text-center">
                      <table class="table storetable">
                        <thead class="text-center">
                          <tr>
                            <th scope="col">full name</th>
                            <th scope="col">full address</th>
                            <th scope="col">membership</th>
                            <th scope="col">membership level</th>
                            <th scope="col">Actions</th>
                          </tr>
                          <tr class="border-0 bg-none invisible">
                            <td class="border-0 bg-none"></td>
                          </tr>
                        </thead>
                        <tbody>
                          {loading ? (
                            <tr>
                              <td colSpan={5}>
                                <Loader />
                              </td>
                            </tr>
                          ) : data?.student_list?.pending_student?.length >
                            0 ? (
                            data?.student_list?.pending_student?.map(
                              (student, i) => (
                                <tr key={i}>
                                  <td
                                    onClick={() => {
                                      navigate(`/student/${student?.id}`);
                                    }}
                                    className="cursor-pointer"
                                  >
                                    {student?.first_name} {student?.last_name}
                                  </td>
                                  <td>
                                    {student?.parent_address
                                      ? `${
                                          student?.parent_address
                                            ?.property_number
                                        }, ${
                                          student?.parent_address?.property_name
                                            ? `${student?.parent_address?.property_name}, `
                                            : ""
                                        } ${
                                          student?.parent_address?.street_number
                                        }, ${student?.parent_address?.town}, ${
                                          student?.parent_address?.country
                                        }-${
                                          student?.parent_address?.postal_code
                                        }`
                                      : "NA"}
                                  </td>
                                  <td>
                                    {student?.membership_details
                                      ? "Active"
                                      : "Trail"}
                                  </td>
                                  <td>{student?.student_type || "NA"}</td>
                                  <td>
                                    <button
                                      className="btn mx-1 attendanceButton"
                                      type="button"
                                      onClick={() => {
                                        StudentAttendance({
                                          dataa: student,
                                          status: "Present",
                                        });
                                      }}
                                      disabled={
                                        student?.attendane_status?.toLowerCase() ===
                                        "present"
                                      }
                                    >
                                      Mark Present
                                    </button>
                                    <button
                                      className="btn mx-1 attendanceButton"
                                      type="button"
                                      onClick={() => {
                                        StudentAttendance({
                                          dataa: student,
                                          status: "Absent",
                                        });
                                      }}
                                      disabled={
                                        student?.attendane_status?.toLowerCase() ===
                                        "absent"
                                      }
                                    >
                                      Mark Absent
                                    </button>
                                    {register_for === "class" && (
                                      <button
                                        className="btn mx-1 attendanceButton"
                                        type="button"
                                        onClick={() => {
                                          setChangeClassForStudentData({
                                            studentId: student?.id,
                                            student_type: student?.student_type,
                                            trialAccountStatus:
                                              student?.membership_details
                                                ? "3"
                                                : "1",
                                            time_slot_for:
                                              student?.student_type,

                                            adult_price: data?.adult_price,
                                            child_price: data?.child_price,
                                            teacherId:
                                              data?.assigned_teacher
                                                ?.teacher_details?.id,
                                            schoolId: data?.school_details?.id,
                                            old_booking_student_id:
                                              student?.old_booking_student_id,
                                            userId: student?.parent_address?.id,
                                          });
                                          handleShowClass();
                                        }}
                                      >
                                        Change Class
                                      </button>
                                    )}
                                  </td>
                                </tr>
                              )
                            )
                          ) : (
                            <td colSpan={5}>No student found.</td>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div
                    class={
                      tab === "Present"
                        ? "tab-pane fade show active"
                        : "tab-pane fade"
                    }
                    id="pills-present"
                    role="tabpanel"
                    aria-labelledby="pills-present-tab"
                  >
                    <div class="table-responsive text-center">
                      <table class="table storetable">
                        <thead class="text-center">
                          <tr>
                            <th scope="col">full name</th>
                            <th scope="col">full address</th>
                            <th scope="col">membership</th>
                            <th scope="col">membership level</th>
                            <th scope="col">Actions</th>
                          </tr>
                          <tr class="border-0 bg-none invisible">
                            <td class="border-0 bg-none"></td>
                          </tr>
                        </thead>
                        <tbody>
                          {loading ? (
                            <td colSpan={5}>
                              <Loader />
                            </td>
                          ) : data?.student_list?.present_student?.length >
                            0 ? (
                            data?.student_list?.present_student?.map(
                              (student, i) => (
                                <tr key={i}>
                                  <td
                                    onClick={() => {
                                      navigate(`/student/${student?.id}`);
                                    }}
                                  >
                                    {student?.first_name} {student?.last_name}
                                  </td>
                                  <td>
                                    {student?.parent_address
                                      ? `${
                                          student?.parent_address
                                            ?.property_number
                                        }, ${
                                          student?.parent_address?.property_name
                                            ? `${student?.parent_address?.property_name}, `
                                            : ""
                                        } ${
                                          student?.parent_address?.street_number
                                        }, ${student?.parent_address?.town}, ${
                                          student?.parent_address?.country
                                        }-${
                                          student?.parent_address?.postal_code
                                        }`
                                      : "NA"}
                                  </td>
                                  <td>
                                    {student?.membership_details
                                      ? "Active"
                                      : "Trail"}
                                  </td>
                                  <td>{student?.student_type || "NA"}</td>
                                  <td>
                                    <button
                                      className="btn mx-1 attendanceButton"
                                      type="button"
                                      onClick={() => {
                                        StudentAttendance({
                                          dataa: student,
                                          status: "Absent",
                                        });
                                      }}
                                    >
                                      Mark Absent
                                    </button>
                                  </td>
                                  {
                                    <button
                                      className="btn mx-1 attendanceButton"
                                      type="button"
                                      onClick={() => {
                                        setChangeClassForStudentData({
                                          studentId: student?.id,
                                          student_type: student?.student_type,
                                          trialAccountStatus:
                                            student?.membership_details
                                              ? "3"
                                              : "1",
                                          time_slot_for: student?.student_type,

                                          adult_price: data?.adult_price,
                                          child_price: data?.child_price,
                                          teacherId:
                                            data?.assigned_teacher
                                              ?.teacher_details?.id,
                                          schoolId: data?.school_details?.id,
                                          old_booking_student_id:
                                            student?.old_booking_student_id,
                                          userId: student?.parent_address?.id,
                                        });
                                        handleShowClass();
                                      }}
                                    >
                                      Change Class
                                    </button>
                                  }
                                </tr>
                              )
                            )
                          ) : (
                            <td colSpan={5}>No student found.</td>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div
                    class={
                      tab === "Absent"
                        ? "tab-pane fade show active"
                        : "tab-pane fade"
                    }
                    id="pills-absent"
                    role="tabpanel"
                    aria-labelledby="pills-absent-tab"
                  >
                    <div class="table-responsive text-center">
                      <table class="table storetable">
                        <thead class="text-center">
                          <tr>
                            <th scope="col">full name</th>
                            <th scope="col">full address</th>
                            <th scope="col">membership</th>
                            <th scope="col">membership level</th>
                            <th scope="col">Actions</th>
                          </tr>
                          <tr class="border-0 bg-none invisible">
                            <td class="border-0 bg-none"></td>
                          </tr>
                        </thead>
                        <tbody>
                          {loading ? (
                            <td colSpan={5}>
                              <Loader />
                            </td>
                          ) : data?.student_list?.absent_student?.length > 0 ? (
                            data?.student_list?.absent_student?.map(
                              (student, i) => (
                                <tr key={i}>
                                  <td
                                    onClick={() => {
                                      navigate(`/student/${student?.id}`);
                                    }}
                                  >
                                    {student?.first_name} {student?.last_name}
                                  </td>
                                  <td>
                                    {student?.parent_address
                                      ? `${
                                          student?.parent_address
                                            ?.property_number
                                        }, ${
                                          student?.parent_address?.property_name
                                            ? `${student?.parent_address?.property_name}, `
                                            : ""
                                        } ${
                                          student?.parent_address?.street_number
                                        }, ${student?.parent_address?.town}, ${
                                          student?.parent_address?.country
                                        }-${
                                          student?.parent_address?.postal_code
                                        }`
                                      : "NA"}
                                  </td>
                                  <td>
                                    {student?.membership_details
                                      ? "Active"
                                      : "Trail"}
                                  </td>
                                  <td>{student?.student_type || "NA"}</td>
                                  <td>
                                    <button
                                      className="btn mx-1 attendanceButton"
                                      type="button"
                                      onClick={() => {
                                        StudentAttendance({
                                          dataa: student,
                                          status: "Present",
                                        });
                                      }}
                                    >
                                      Mark Present
                                    </button>
                                  </td>
                                  {
                                    <button
                                      className="btn mx-1 attendanceButton"
                                      type="button"
                                      onClick={() => {
                                        setChangeClassForStudentData({
                                          studentId: student?.id,
                                          student_type: student?.student_type,
                                          trialAccountStatus:
                                            student?.membership_details
                                              ? "3"
                                              : "1",
                                          time_slot_for: student?.student_type,

                                          adult_price: data?.adult_price,
                                          child_price: data?.child_price,
                                          teacherId:
                                            data?.assigned_teacher
                                              ?.teacher_details?.id,
                                          schoolId: data?.school_details?.id,
                                          old_booking_student_id:
                                            student?.old_booking_student_id,
                                          userId: student?.parent_address?.id,
                                        });
                                        handleShowClass();
                                      }}
                                    >
                                      Change Class
                                    </button>
                                  }
                                </tr>
                              )
                            )
                          ) : (
                            <td colSpan={5}>No student found.</td>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {modalName === "add" && (
        <AddStudent
          show={show}
          handleShow={handleShow}
          setModalName={setModalName}
        />
      )}

      <AddStudent
        show={showAddStudent}
        handleShow={toggle}
        handleAddStudentToRegister={(_student, _time, _date) => {
          addStudentToRegister(_student, _time, _date);
        }}
        availableDates={availableDates}
        availableTimeSlots={availableTimeSlots}
        register_for={register_for}
        students={register_for === "class" ? studentForClass : []}
        class_type={
          register_for === "class"
            ? data?.class_type
            : register_for === "event"
            ? data?.age_group
            : ""
        }
      />

      {changeClassForStudentData && (
        <ChangeStudentClass
          show={showClass}
          handleShow={handleShowClass}
          id={selectedId}
          class_type={changeClassForStudentData?.time_slot_for}
          changeClassesForStudentData={(
            val,
            dates,
            selectedClass,
            start_time,
            end_time,
            time_slot_id
          ) => {
            setChangeClassForStudentData((prev) => {
              return {
                ...prev,
                classId: val,
              };
            });

            handleChangeClass({
              ...changeClassForStudentData,
              classId: val,
              date: dates.date,
              start_time: dates?.start_time,
              end_time: dates?.end_time,
              time_slot_id: dates?.time_slot_id,
              total_amount:
                changeClassForStudentData?.time_slot_for.toLowerCase() ===
                "child"
                  ? selectedClass?.child_price
                  : changeClassForStudentData?.time_slot_for.toLowerCase() ===
                    "adult"
                  ? selectedClass?.adult_price
                  : 0,
            });
          }}
        />
      )}
    </section>
  );
}

export default Register;
