import { useCallback, useState } from "react";
export const padTime = (time) => {
  return String(time).length === 1 ? `0${time}` : `${time}`;
};

export const format = (time) => {
  // Convert seconds into minutes and take the whole part
  const minutes = Math.floor(time / 60);

  // Get the seconds left after converting minutes
  const seconds = time % 60;

  //Return combined values as string in format mm:ss
  return `${minutes}:${padTime(seconds)}`;
};

export const config = {
  bucketName: process.env.REACT_APP_BUCKET_NAME,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
  dirName: "dev",
};

export const useToggle = (initial) => {
  const [open, setOpen] = useState(initial);

  return [open, useCallback(() => setOpen((status) => !status), [])];
};
