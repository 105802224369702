import moment from "moment";
import React, { useRef } from "react";
import { Button } from "react-bootstrap";
import ReactToPrint from "react-to-print";

export default function PrintComponent({ data, date, time }) {
  let componentRef = useRef();

  return (
    <>
      <div>
        {/* button to trigger printing of target component */}
        <ReactToPrint
          trigger={() => (
            <button className="btn btn-view btn-accept">Print</button>
          )}
          content={() => componentRef}
        />

        {/* component to be printed */}
        <div style={{ display: "none" }}>
          <ComponentToPrint
            ref={(el) => (componentRef = el)}
            data={data}
            date={date}
            time={time}
          />
        </div>
      </div>
    </>
  );
}

class ComponentToPrint extends React.Component {
  render() {
    return (
      <div className="p-3">
        <h2 className="theme-color">Attendance</h2>
        <p className="mb-0">
          <label className="fw-bold">Class Name: </label>{" "}
          {this.props.data?.name}
        </p>
        <p className="mb-0">
          <label className="fw-bold">Date & Time: </label>{" "}
          {this.props.date
            ? `${moment(this.props.date?.date).format("DD MMM YYYY")}, ${moment(
                this.props.time.start_time,
                "hh:mm:ss"
              ).format("hh:mm a")}-${moment(
                this.props.time.end_time,
                "hh:mm:ss"
              ).format("hh:mm a")}`
            : "NA"}
        </p>
        {/* <p>
          <label className="fw-bold">Time: </label>{" "}
          {this.props.time
            ? moment(this.props.time.start_time, "hh:mm:ss").format("hh:mm a")
            : "NA"}
        </p> */}
        <p className="mb-0">
          <label className="fw-bold">Venue:</label>{" "}
          {this.props.data?.school_details?.name}{" "}
          {this.props.data?.school_details?.address}
        </p>
        <p className="mb-0">
          <label className="fw-bold">Teacher:</label>{" "}
          {this.props.data?.assigned_teacher?.teacher_details?.full_name}{" "}
        </p>
        <div className="table-responsive text-center table-bordered">
          <table className="table border mt-4">
            <thead class="text-center border">
              <th scope="col border" style={{ width: "150px" }}>
                Name
              </th>
              <th scope="col border ms-1" style={{ width: "120px" }}>
                Attendance
              </th>
              <th scope="col border ms-1" style={{ width: "100px" }}>
                Mark
              </th>
              <th scope="col border">Notes</th>
              {/* <tr class="border-0 bg-none invisible">
                <td class="border-0 bg-none"></td>
              </tr> */}
            </thead>
            <tbody>
              {this.props.data?.student_list?.pending_student?.map(
                (student, i) => {
                  return (
                    <tr key={i} className="py-3 border">
                      <td className="border ellipsis">
                        {student?.first_name} {student?.last_name}
                      </td>
                      <td className="border" style={{ width: "120px" }}></td>
                      <td className="border" style={{ width: "100px" }}></td>
                      <td className="border"></td>
                    </tr>
                  );
                }
              )}
              {/* {this.props.data?.student_list?.present_student?.map(
                (student, i) => {
                  return (
                    <tr key={i}>
                      <td className="px-2">{student?.id}</td>
                      <td className="px-2">
                        {student?.first_name} {student?.last_name}
                      </td>
                      <td className="px-2 ellipsis">
                        {student?.parent_address
                          ? `${student?.parent_address?.property_number}, ${
                              student?.parent_address?.property_name
                                ? `${student?.parent_address?.property_name}, `
                                : ""
                            } ${student?.parent_address?.street_number}, ${
                              student?.parent_address?.town
                            }, ${student?.parent_address?.country}-${
                              student?.parent_address?.postal_code
                            }`
                          : "NA"}
                      </td>
                      <td className="px-2"></td>
                    </tr>
                  );
                }
              )}
              {this.props.data?.student_list?.absent_student?.map(
                (student, i) => {
                  return (
                    <tr key={i}>
                      <td className="px-2">{student?.id}</td>
                      <td className="px-2">
                        {student?.first_name} {student?.last_name}
                      </td>
                      <td className="px-2 ellipsis">
                        {student?.parent_address
                          ? `${student?.parent_address?.property_number}, ${
                              student?.parent_address?.property_name
                                ? `${student?.parent_address?.property_name}, `
                                : ""
                            } ${student?.parent_address?.street_number}, ${
                              student?.parent_address?.town
                            }, ${student?.parent_address?.country}-${
                              student?.parent_address?.postal_code
                            }`
                          : "NA"}
                      </td>
                      <td className="px-2">Absent</td>
                    </tr>
                  );
                }
              )} */}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
