import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { pageTransitionVaraint, staggerVariant } from "../../constant/Variants";

function CreateAccount() {
  const navigate = useNavigate();
  return (
    <section className="bg-image">
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6">
            <div className="logo-bg position-relative">
              <p>
                <img
                  src="assets/img/headerlogo.png"
                  className="logo-icon"
                  alt="logo"
                />
              </p>
              <p>
                <img
                  src="assets/img/banner_logo.png"
                  className="logo-img"
                  alt="logo"
                />
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6">
            <div className="form-style">
              <Formik
                initialValues={{
                  fullName: "",
                  email: "",
                  countryCode: "91",
                  mobileNumber: "",
                  password: "",
                  image: "",
                  tnc: false,
                }}
                validationSchema={Yup.object({
                  fullName: Yup.string().required("Required"),
                  email: Yup.string()
                    .email("Kindly enter a valid email.")
                    .required("Required"),
                  mobileNumber: Yup.number()
                    .required("Required")
                    .positive("Kindly enter a valid number."),
                  countryCode: Yup.number()
                    .required("Required")
                    .positive("Kindly enter a valid number."),
                  password: Yup.string()
                    .matches(
                      // eslint-disable-next-line no-useless-escape
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#£€\$%\^&\*])(?=.{8,})/,
                      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
                    )
                    .required("Required"),
                  // image: Yup.string().required("Required"),
                  tnc: Yup.boolean().oneOf([true], "Required"),
                })}
                onSubmit={({ values }) => {
                  navigate("/qualification-info", { replace: true });
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                }) => (
                  <form className="row" onSubmit={handleSubmit}>
                    <div className="form-group col-sm-12 col-md-12 text-center mb-4">
                      <h2 className="mdl-ttl">create account</h2>
                    </div>
                    <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4 text-center">
                      <h5>Personal Info</h5>
                      <div className="upload-btn-wrapper">
                        <button className="btn">
                          <img src="assets/img/upload-img.png" alt="" />

                          <div className="camera-icon">
                            <i className="fa fa-camera"></i>
                          </div>
                        </button>
                        <input type="file" name="myfile" />
                      </div>
                    </div>
                    <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                      <div className="input-container">
                        <input
                          type="text"
                          className="form-control ps-4"
                          placeholder="Full Name"
                          name="fullName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.fullName}
                        />
                        <img
                          src="assets/img/First-Name.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                      {errors.fullName && touched.fullName && (
                        <div className="text-red">{errors.fullName}</div>
                      )}
                    </div>
                    <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                      <div className="input-container">
                        <input
                          type="email"
                          className="form-control ps-4"
                          placeholder="Email Address"
                          name="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                        />
                        <img
                          src="assets/img/email.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                      {errors.email && touched.email && (
                        <div className="text-red">{errors.email}</div>
                      )}
                    </div>
                    <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                      <div className="input-container">
                        {/* <input
                            id="phone"
                            type="tel"
                            className="form-control"
                            placeholder="Mobile Number"
                          /> */}
                        <PhoneInput
                          inputProps={{
                            name: "mobileNumber",
                          }}
                          country={"in"}
                          value={values.countryCode + values.mobileNumber}
                          onChange={(phone, country, e, formattedValue) => {
                            if (phone.length > 0) {
                              const raw = phone.slice(
                                country?.dialCode?.length
                              );
                              setFieldValue("mobileNumber", raw);

                              setFieldValue(
                                "countryCode",
                                formattedValue?.split(" ")[0]
                              );
                            } else {
                              setFieldValue("mobileNumber", "");

                              setFieldValue("countryCode", "");
                            }
                          }}
                          onBlur={handleBlur}
                          placeholder="Mobile Number"
                          containerClass="form-control"
                          inputClass="phoneInputClass"
                          style={{
                            top: -7,
                            padding: "10px",
                          }}
                        />
                        <img
                          src="assets/img/mobile.png"
                          className="input-img"
                          alt=""
                          style={{
                            top: "15px",
                          }}
                        />
                      </div>
                      {errors.mobileNumber && touched.mobileNumber && (
                        <div className="text-red">{errors.mobileNumber}</div>
                      )}
                    </div>
                    <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                      <div className="input-container">
                        <input
                          id="password-field"
                          type="password"
                          className="form-control ps-4"
                          placeholder="Set Password"
                          name="password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                        />
                        <img
                          src="assets/img/password.png"
                          className="input-img pass"
                          alt=""
                        />
                        <span
                          toggle="#password-field"
                          className="pass-view field-icon toggle-password"
                        ></span>
                      </div>
                      {errors.password && touched.password && (
                        <div className="text-red">{errors.password}</div>
                      )}
                    </div>
                    <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4 text-center">
                      <div className="input-container term-checkbox">
                        <input
                          type="checkbox"
                          id="term-checkbox"
                          name="tnc"
                          checked={values.tnc}
                          onChange={(e) => {
                            setFieldValue("tnc", e.target.checked);
                          }}
                          onBlur={handleBlur}
                        />
                        <label htmlFor="term-checkbox">
                          I consent to
                          <a href="#" className="theme-color fw-bold">
                            Data
                          </a>{" "}
                          &
                          <a href="#" className="theme-color fw-bold">
                            Terms of Site
                          </a>
                        </label>
                        {errors.tnc && touched.tnc && (
                          <div className="text-red">{errors.tnc}</div>
                        )}
                      </div>
                    </div>
                    <div className="form-group col-sm-12 col-md-12 text-center">
                      <button type="submit" className="btn btn-submit">
                        Create Account
                      </button>
                    </div>

                    <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4 text-center">
                      <div className="input-container">
                        <p>
                          Already have an account?
                          <a href="login.html" className="theme-color fw-bold">
                            Log In
                          </a>
                        </p>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CreateAccount;
