import React from "react";

function Myexpertise() {
  return (
    <section className="sec-bg py-4 px-3 bottom-space">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
            <h3>Manage My Expertise</h3>
          </div>
        </div>

        <div className="row Expertise-list">
          <div className="col-sm-12 col-md-6 col-lg-4 col-xl-3 mb-4">
            <div className="expertise bg-white p-2 rounded shadow position-relative current">
              <div className="joining-flex mt-0">
                <span>
                  <div className="event-box-flex">
                    <span>
                      <img
                        src="assets/img/kick.png"
                        className="event-img"
                        alt=""
                      />
                    </span>
                    <span>
                      <h5>krante</h5>
                    </span>
                  </div>
                </span>
                <div>
                  <span className="cursor-pointer exper-btn">
                    <img src="assets/img/edit.png" alt="" />
                  </span>
                  <span className="cursor-pointer exper-btn">
                    <img src="assets/img/delete-sm.png" alt="" />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4 col-xl-3 mb-4">
            <div className="expertise bg-white p-2 rounded shadow position-relative">
              <div className="joining-flex mt-0">
                <span>
                  <div className="event-box-flex">
                    <span>
                      <img
                        src="assets/img/kick.png"
                        className="event-img"
                        alt=""
                      />
                    </span>
                    <span>
                      <h5>krante</h5>
                    </span>
                  </div>
                </span>
                <div>
                  <span className="cursor-pointer exper-btn">
                    <img src="assets/img/edit.png" alt="" />
                  </span>
                  <span className="cursor-pointer exper-btn">
                    <img src="assets/img/delete-sm.png" alt="" />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4 col-xl-3 mb-4">
            <div className="expertise bg-white p-2 rounded shadow position-relative">
              <div className="joining-flex mt-0">
                <span>
                  <div className="event-box-flex">
                    <span>
                      <img
                        src="assets/img/kick.png"
                        className="event-img"
                        alt=""
                      />
                    </span>
                    <span>
                      <h5>krante</h5>
                    </span>
                  </div>
                </span>
                <div>
                  <span className="cursor-pointer exper-btn">
                    <img src="assets/img/edit.png" alt="" />
                  </span>
                  <span className="cursor-pointer exper-btn">
                    <img src="assets/img/delete-sm.png" alt="" />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4 col-xl-3 mb-4">
            <div className="expertise bg-white p-2 rounded shadow position-relative">
              <div className="joining-flex mt-0">
                <span>
                  <div className="event-box-flex">
                    <span>
                      <img
                        src="assets/img/kick.png"
                        className="event-img"
                        alt=""
                      />
                    </span>
                    <span>
                      <h5>krante</h5>
                    </span>
                  </div>
                </span>
                <div>
                  <span className="cursor-pointer exper-btn">
                    <img src="assets/img/edit.png" alt="" />
                  </span>
                  <span className="cursor-pointer exper-btn">
                    <img src="assets/img/delete-sm.png" alt="" />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4 col-xl-3 mb-4">
            <div className="expertise bg-white p-2 rounded shadow position-relative">
              <div className="joining-flex mt-0">
                <span>
                  <div className="event-box-flex">
                    <span>
                      <img
                        src="assets/img/kick.png"
                        className="event-img"
                        alt=""
                      />
                    </span>
                    <span>
                      <h5>krante</h5>
                    </span>
                  </div>
                </span>
                <div>
                  <span className="cursor-pointer exper-btn">
                    <img src="assets/img/edit.png" alt="" />
                  </span>
                  <span className="cursor-pointer exper-btn">
                    <img src="assets/img/delete-sm.png" alt="" />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4 col-xl-3 mb-4">
            <div className="expertise bg-white p-2 rounded shadow position-relative">
              <div className="joining-flex mt-0">
                <span>
                  <div className="event-box-flex">
                    <span>
                      <img
                        src="assets/img/kick.png"
                        className="event-img"
                        alt=""
                      />
                    </span>
                    <span>
                      <h5>krante</h5>
                    </span>
                  </div>
                </span>
                <div>
                  <span className="cursor-pointer exper-btn">
                    <img src="assets/img/edit.png" alt="" />
                  </span>
                  <span className="cursor-pointer exper-btn">
                    <img src="assets/img/delete-sm.png" alt="" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
            <h3>Edit Expertise</h3>
          </div>
          <div className="col-sm-12 col-md-8 col-lg-6 mb-4">
            <form className="form-style mw-100">
              <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                <div className="input-container">
                  <select className="form-control ps-4 form-select">
                    <option>Expertise in Subject</option>
                  </select>
                  <img
                    src="assets/img/expertise.png"
                    className="input-img"
                    alt=""
                  />
                </div>
              </div>
              <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                <button className="btn btn-submit">Save Changes</button>
              </div>
            </form>
          </div>
        </div>

        <div className="row align-items-center">
          <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
            <hr />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
            <h3>Add New Expertise</h3>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
            <form className="form-style row mw-100 newAdd-expertise student-container">
              <div className="form-group col-sm-6 col-md-4 col-lg-3 mb-4">
                <div className="">
                  <input type="radio" name="expertise" id="expertise1" />
                  <label
                    for="expertise1"
                    className="new-expertise student__details bg-white p-4 rounded h-100 position-relative w-100"
                  >
                    <div className="">
                      <div className="event-box-flex align-items-normal p-0">
                        <span>
                          <h6 className="mb-0">karate</h6>
                        </span>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              <div className="form-group col-sm-6 col-md-4 col-lg-3 mb-4">
                <div className="">
                  <input type="radio" name="expertise" id="expertise2" />
                  <label
                    for="expertise2"
                    className="new-expertise student__details bg-white p-4 rounded h-100 position-relative w-100"
                  >
                    <div className="">
                      <div className="event-box-flex align-items-normal p-0">
                        <span>
                          <h6 className="mb-0">boxing</h6>
                        </span>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              <div className="form-group col-sm-6 col-md-4 col-lg-3 mb-4">
                <div className="">
                  <input type="radio" name="expertise" id="expertise3" />
                  <label
                    for="expertise3"
                    className="new-expertise student__details bg-white p-4 rounded h-100 position-relative w-100"
                  >
                    <div className="">
                      <div className="event-box-flex align-items-normal p-0">
                        <span>
                          <h6 className="mb-0">boxing</h6>
                        </span>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              <div className="form-group col-sm-6 col-md-4 col-lg-3 mb-4">
                <div className="">
                  <input
                    type="radio"
                    name="expertise"
                    id="expertise4"
                    checked
                  />
                  <label
                    for="expertise4"
                    className="new-expertise student__details bg-white p-4 rounded h-100 position-relative w-100"
                  >
                    <div className="">
                      <div className="event-box-flex align-items-normal p-0">
                        <span>
                          <h6 className="mb-0">boxing</h6>
                        </span>
                      </div>
                    </div>
                  </label>
                </div>
                <div className="input-container mt-2">
                  <select className="form-control ps-4 form-select">
                    <option>Select Belt</option>
                  </select>
                  <img src="assets/img/belt.png" className="input-img" alt="" />
                </div>
                <div className="input-container mt-2">
                  <select className="form-control ps-4 form-select">
                    <option>No. of Year</option>
                  </select>
                  <img
                    src="assets/img/no-year.png"
                    className="input-img"
                    alt=""
                  />
                </div>
                <div className="input-container">
                  <button className="btn btn-submit w-100 fs-5 mt-3">
                    Add New
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Myexpertise;
